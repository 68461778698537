import React from "react";
import { Link } from "react-scroll";

const Banner = ({ title, image, link }) => {
  return (
    <div>
      <section
        className=""
        style={{
          padding: "25px 0",
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <div className="parallax parallax--ser-li">
          <div
            className="container clearfix"
            style={{
              background: "rgba(255, 255, 255, 0.8)",
              textAlign: "center",
              padding: "25px",
            }}
          >
            <div className="row">
              <div className="col-md-12">
                <h2 style={{ fontSize: "25px", fontFamily: "theano" }}>
                  {" "}
                  {title}{" "}
                </h2>
                <ul className="breadcrumb__list">
                  {/* <li>
                            <Link to="/">Home</Link>
                        </li> */}

                  {/* <li>{link}</li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Banner;
