import React, { useEffect } from "react";
import JwModal from "jw-react-modal";
import { IoIosClose } from "react-icons/io";
import { Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Project = ({
  id,
  name,
  titleFR,
  description,
  descriptionFR,
  modalTitle,
  image,
}) => {
  const { i18n, t } = useTranslation();

  const options = {
    dots: true,
    infinite: true,
    speed: 500,
    // autoPlay: true,
    loop: true,
    autoplaySpeed: 1000,
    initialSlide: 0,
  };

  // console.log("IMAGE ==>", image);

  return (
    //
    <Col md={4} className="mb-5">
      <div
        href="#project8"
        className="project-item item-shadow popup-with-zoom-anim"
      >
        <img alt="" className="img-responsive" src={image[0] && image[0].url} />
        <div className="project-hover">
          <Link to={`/project-detail/${id}`} className="link-arrow">
            {t("projet.text")} <i className="icon ion-ios-arrow-right"></i>
          </Link>
          <div className="project-hover-content">
            <h3 className="project-title">
              {/* {i18n.language === "fr" ? titleFR : title} */}
              {name}
            </h3>
            <p
              className="project-description"
              // style={{
              //   padding: "20px",
              // }}
            >
              {/* {i18n.language === "fr" ? descriptionFR : description} */}
              {description}
            </p>
          </div>
        </div>
      </div>
      {/* <JwModal
        id={`jw-modal-${id}`}
        className="container zoom-anim-dialog mfp-hide"
        style={{
          width: "100px",
        }}
      >
        <div className="row">
          <div className="col-lg-6" style={{ maxWidth: "45%" }}>
            <Slider {...options}>
              {image.map((img, index) => (
                <img
                  key={index}
                  alt=""
                  className="project-detail-img"
                  src={img}
                />
              ))}
            </Slider>
          </div>
          <div className="col-lg-4">
            <a onClick={JwModal.close(`jw-modal-${id}`)}>
              <IoIosClose size={30} />
            </a>
            <h3 className="project-detail-title">
              {i18n.language === "fr" ? titleFR : title}
            </h3>
            <br />
            <p className="project-detail-text">
              {i18n.language === "fr" ? descriptionFR : description}
            </p>
          </div>
        </div>
      </JwModal> */}
    </Col>
  );
};

export default Project;
