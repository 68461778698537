import React, { useState, useEffect, Fragment } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Fade from "react-reveal/Fade"
import {
  IoIosSearch,
  IoMdPerson,
  IoIosHeartEmpty,
  IoIosCart,
  IoIosMenu,
  IoIosArrowDown
} from "react-icons/io"
import Navigation from "./elements/Navigation"
import AboutOverlay from "./elements/AboutOverlay"
import SearchOverlay from "./elements/SearchOverlay"
import CartOverlay from "./elements/CartOverlay"
import WishlistOverlay from "./elements/WishlistOverlay"
import MobileMenu from "./elements/MobileMenu"

const HeaderEight = ({ aboutOverlay, cartItems, wishlistItems }) => {
  const [scroll, setScroll] = useState(0)
  const [headerTop, setHeaderTop] = useState(0)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [offCanvasAboutActive, setOffCanvasAboutActive] = useState(false)
  const [offCanvasSearchActive, setOffCanvasSearchActive] = useState(false)
  const [offCanvasCartActive, setOffCanvasCartActive] = useState(false)
  const [offCanvasWishlistActive, setOffCanvasWishlistActive] = useState(false)
  const [offCanvasMobileMenuActive, setOffCanvasMobileMenuActive] =
    useState(false)

  useEffect(() => {
    const header = document.querySelector("header")

    setHeaderTop(header.offsetTop)
    setHeaderHeight(header.offsetHeight)
    window.addEventListener("scroll", handleScroll)
    scroll > headerTop
      ? (document.body.style.paddingTop = `${headerHeight}px`)
      : (document.body.style.paddingTop = 0)
  }, [])

  const handleScroll = () => {
    setScroll(window.scrollY)
  }

  return (
    <Fragment>
      <header
        className={`topbar-shadow transparent-style white-content ${
          scroll > headerTop ? "is-sticky" : "  "
        }`}
      >
        <div className="multilevel-header">
          <Container className="wide">
            <Row className="header-content multilevel-header__top">
              <Col lg={4} className="d-none d-lg-block">
                <div className="d-flex header-content__navigation">
                  <div className="language-change change-dropdown">
                    <span>English</span> <IoIosArrowDown />
                    <ul>
                      <li>
                        <button>English</button>
                      </li>
                      <li>
                        <button>Deustch</button>
                      </li>
                    </ul>
                  </div>
                  <span className="header-separator">|</span>
                  <div className="currency-change change-dropdown">
                    <span>USD</span> <IoIosArrowDown />
                    <ul>
                      <li>
                        <button>USD</button>
                      </li>
                      <li>
                        <button>EUR</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col xs={6} lg={4} className="text-left text-lg-center">
                {/* logo */}
                <div className="header-content__logo">
                  <Fade top>
                    <Link to="/">
                      <img
                        src={
                          window.location.pathname !== "/"
                            ? "/images/Logo-new.png"
                            : "/images/Logo-old-2.png"
                        }
                        className="img-fluid white-logo"
                        alt=""
                        style={{ width: "300px" }}
                      />
                    </Link>
                  </Fade>
                </div>
              </Col>
              <Col xs={6} lg={4} className="text-right">
                <div className="header-content__icons">
                  <ul className="d-none d-lg-block">
                    {/* <li>
                      <button
                        onClick={() => {
                          setOffCanvasSearchActive(true);
                          document
                            .querySelector("body")
                            .classList.add("overflow-hidden");
                        }}
                      >
                        <IoIosSearch />
                      </button>
                    </li> */}
                    <li>
                      <Link to="">
                        <IoMdPerson />
                      </Link>
                    </li>
                    <li>
                      <button
                        onClick={() => {
                          setOffCanvasWishlistActive(true)
                          document
                            .querySelector("body")
                            .classList.add("overflow-hidden")
                        }}
                      >
                        <IoIosHeartEmpty />
                        {wishlistItems.length >= 1 ? (
                          <span className="count">
                            {wishlistItems.length ? wishlistItems.length : ""}
                          </span>
                        ) : (
                          ""
                        )}
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => {
                          setOffCanvasCartActive(true)
                          document
                            .querySelector("body")
                            .classList.add("overflow-hidden")
                        }}
                      >
                        <IoIosCart />
                        {cartItems.length >= 1 ? (
                          <span className="count">
                            {cartItems.length ? cartItems.length : ""}
                          </span>
                        ) : (
                          ""
                        )}
                      </button>
                    </li>
                  </ul>

                  <ul className="d-block d-lg-none">
                    <li>
                      <Link to="">
                        <IoIosHeartEmpty />
                        {wishlistItems.length >= 1 ? (
                          <span className="count">
                            {wishlistItems.length ? wishlistItems.length : ""}
                          </span>
                        ) : (
                          ""
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <IoIosCart />
                        {cartItems.length >= 1 ? (
                          <span className="count">
                            {cartItems.length ? cartItems.length : ""}
                          </span>
                        ) : (
                          ""
                        )}
                      </Link>
                    </li>
                    <li>
                      <button
                        onClick={() => setOffCanvasMobileMenuActive(true)}
                      >
                        <IoIosMenu />
                      </button>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row className="multilevel-header__bottom">
              <Col
                lg={12}
                className="text-center d-none d-lg-block space-mt--40"
              >
                {/* navigation */}

                <Navigation />
              </Col>
            </Row>
          </Container>
        </div>
      </header>

      {/* about overlay */}
      {aboutOverlay === false ? (
        ""
      ) : (
        <AboutOverlay
          activeStatus={offCanvasAboutActive}
          getActiveStatus={setOffCanvasAboutActive}
        />
      )}
      {/* search overlay */}
      <SearchOverlay
        activeStatus={offCanvasSearchActive}
        getActiveStatus={setOffCanvasSearchActive}
      />

      {/* cart overlay */}
      <CartOverlay
        activeStatus={offCanvasCartActive}
        getActiveStatus={setOffCanvasCartActive}
      />

      {/* wishlist overlay */}
      <WishlistOverlay
        activeStatus={offCanvasWishlistActive}
        getActiveStatus={setOffCanvasWishlistActive}
      />
      {/* Mobile Menu */}
      <MobileMenu
        activeStatus={offCanvasMobileMenuActive}
        getActiveStatus={setOffCanvasMobileMenuActive}
      />
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData,
    wishlistItems: state.wishlistData
  }
}

export default connect(mapStateToProps)(HeaderEight)
