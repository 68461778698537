import React from "react"
import { Link } from "react-router-dom"
import Swiper from "react-id-swiper"
import { Container, Row, Col } from "react-bootstrap"
import { Title } from "./HeroSlide.style"

const HeroSliderSeven = ({ sliderData }) => {
  const params = {
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    effect: "fade",
    watchSlidesVisibility: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    renderPrevButton: () => (
      <button className="swiper-button-prev ht-swiper-button-nav"></button>
    ),
    renderNextButton: () => (
      <button className="swiper-button-next ht-swiper-button-nav"></button>
    )
  }

  return (
    <div className="hero-slider-seven">
      <div
        className="hero-slider-seven__wrapper"
        style={{
          zIndex: "-1"
        }}
      >
        <Swiper {...params}>
          {sliderData &&
            sliderData.map((single, i) => {
              return (
                <div
                  className="hero-slider-seven__slide bg-img"
                  style={{
                    backgroundImage: `url(${single.image})`,
                    textAlign: "center"
                  }}
                  key={i}
                >
                  <Container className="h-100">
                    <Row
                      className="align-items-center flex-column flex-lg-row justify-content-center justify-content-lg-start h-100"
                      style={{
                        marginTop: "100px"
                      }}
                    >
                      <div
                        className=""
                        style={{
                          margin: "auto",
                          marginTop: "150px",
                          fontFamily: "times new roman",
                          opacity: "1"
                        }}
                      >
                        <div className="hero-slider-seven__content">
                          {/* <h5 className="sub-title">{single.subtitle}</h5> */}
                          <Title

                          // dangerouslySetInnerHTML={{ __html: single.title }}
                          >
                            <h1
                              className="title"
                              style={{
                                textTransform: "lowercase",
                                fontFamily: "times new roman",
                                color: "black",
                                backgroundColor: "rgba(255, 255, 255, 0.8)",
                                padding: "20px",
                                width: "100%"
                              }}
                            >
                              Parce que la déco est comme une thérapie,
                              <br /> le bonheur se trouve dans votre maison,
                              <br /> créons-le ensemble
                            </h1>
                          </Title>
                          <div className="slider-link">
                            <Link to=""></Link>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </Container>
                  <div className="hero-slider-seven__pagination">
                    <span className="current">{i + 1}</span>
                    <span className="border"></span>
                    <span className="total">{sliderData.length}</span>
                  </div>
                </div>
              )
            })}
        </Swiper>
      </div>
    </div>
  )
}

export default HeroSliderSeven
