import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Container, Row, Col } from "react-bootstrap"
import { connect } from "react-redux"
import { getDiscountPrice } from "../../lib/product"
import {
  IoIosCheckmark,
  IoIosCheckmarkCircleOutline,
  IoMdCash
} from "react-icons/io"
import { LayoutFive } from "../../components/Layout"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { saveShippingAddress } from "../../redux/actions/userActions"
import { createOrder, updateOrder } from "../../redux/actions/orderActions"
import dayjs from "dayjs"
import Banner from "../../components/Banner/Banner"
import axios from "axios"
import swal from "sweetalert"
import PaypalButton from "../../components/Payment/Paypal"
import StripeButton from "../../components/Payment/Stripe"
import SumpupButton from "../../components/Payment/Sumup"
import {
  Steps,
  Button,
  message,
  PageHeader,
  Form,
  Input,
  Radio,
  Select,
  Descriptions,
  notification
} from "antd"
import "antd/dist/antd.css"
import "react-phone-input-2/lib/material.css"
import PhoneInput from "react-phone-input-2"

import { deleteAllFromCart } from "../../redux/actions/cartActions"
import {
  getOrderDetails,
  payOrder,
  deliverOrder
} from "../../redux/actions/orderActions.js"
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET
} from "../../redux/constants/orderConstant"

import { useForm } from "react-hook-form"

const { Step } = Steps

const Checkout = ({ cartItems }) => {
  const cart = useSelector((state) => state.cartData)
  // console.log("CART ==>", cart)

  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }

  let data = {}

  data.itemsPrice = addDecimals(
    cart.reduce(
      (acc, item) =>
        acc + getDiscountPrice(item.price, item.discount) * item.quantity,
      0
    )
  )

  // cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 0 : 100)
  data.taxPrice = addDecimals(Number((0.2 * data.itemsPrice).toFixed(2)))
  // data.totalPrice = (
  //   parseFloat(Number(data.itemsPrice).toFixed(2)) +
  //   parseFloat(Number(data.taxPrice).toFixed(2))
  // ).toFixed(2);
  data.totalPrice = (
    parseFloat(Number(data.itemsPrice).toFixed(2)) + 4.5
  ).toFixed(2)

  let [cartPriceHT, setCartPriceHT] = React.useState(data.itemsPrice)
  let [cartPriceTVA, setCartPriceTVA] = React.useState(data.taxPrice)
  let [cartPriceTTC, setCartPriceTTC] = React.useState(data.totalPrice)

  // let cartPriceHT = 0;
  // let cartPriceTVA = 0;
  // let cartPriceTTC = 0;
  let totalFrais = 0

  const param = useParams()
  let search = window.location.search
  const checkoutId = new URLSearchParams(search).get("checkout_id")
  const currentId = new URLSearchParams(search).get("current")
  const paymentId = new URLSearchParams(search).get("payment")

  const dispatch = useDispatch()
  const history = useHistory()

  const shipping = useSelector((state) => state.shippingAddress)

  const orderCreate = useSelector((state) => state.orderCreate)
  const orderUpdate = useSelector((state) => state.orderUpdate)

  const { shippingAddress } = shipping
  const { order, success, error } = orderCreate
  const { order: orderU, successU, errorU } = orderUpdate

  const [paymentMethod, setPaymentMethod] = useState("")
  const [coupon, setCoupon] = useState("")

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [phone, setPhone] = useState(
    shippingAddress ? shippingAddress.phone : ""
  )

  const [current, setCurrent] = React.useState(currentId || 0)
  const [check, setCheck] = React.useState(false)
  const [paid, setPaid] = React.useState(false)

  const [checkout, setCheckout] = React.useState()
  const { errors, reset } = useForm()

  const [form] = Form.useForm()

  const openNotificationWithIcon = (type, title, message) => {
    notification[type]({
      message: title,
      description: message
    })
  }

  useEffect(() => {
    if (!userInfo) {
      history.push("/login-register")
    }
    document.querySelector("body").classList.remove("overflow-hidden")

    if (checkoutId) {
      axios
        .post(`${process.env.REACT_APP_REST_API_URL}api/payment/sumup/finish`, {
          id: checkoutId
        })
        .then((res) => {
          // console.log("res.data.data ==>", res.data.data)
          setCheckout(res.data.data)
          successSumup(res.data.data)
        })
    }

    // console.log("userInfo ME ==>", userInfo)
    // console.log("ORDER ===>", order)
  }, [])

  const next = async () => {
    if (current === 0) {
      const { err } = await form.validateFields([
        "address",
        "city",
        "country",
        "zip",
        "phone",
        "firstname",
        "lastname"
      ])

      if (!err) {
        if (!check) {
          openNotificationWithIcon(
            "error",
            "Conditions générales de ventes",
            "case à cocher conditions générales de vente"
          )
          setCurrent(current)
          return
        } else {
          setCurrent(current + 1)
        }
      }
    }
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const successPaypal = async (paymentResult) => {
    // console.log("paymentResult PAYPAL ==> ", paymentResult)
    const { address, zip, city, country, firstname, lastname } =
      form.getFieldsValue()

    let shipping = {
      firstname,
      lastname,
      address,
      city,
      email: userInfo.email,
      postalCode: zip,
      country,
      phone
    }

    if (paymentResult.status === "COMPLETED") {
      dispatch(
        createOrder({
          orderItems: cart,
          shippingAddress: shipping,
          itemsPrice: data.itemsPrice,
          paymentResult,
          taxPrice: data.taxPrice,
          livraison: "Offert*",
          orderNo: orderNo,
          totalPrice: cartPriceTTC,
          paymentMethod
        })
      )

      swal(
        "achat effectué!"
        // "Nous espérons vous revoir bientôt.",
      ).then(() => {
        //   console.log("HELLO ===>", order);
        dispatch(deleteAllFromCart())
        setPaid(true)
      })
    } else {
      swal("Payement refusé")
    }
  }

  // console.log("ORDER ===> N2", order)
  const successSumup = (paymentResult) => {
    // paymentResult.status = "PAID";
    let finalResult
    // console.log("paymentResult 1 ==> ", paymentResult)

    const { address, zip, city, country, firstname, lastname } =
      form.getFieldsValue()

    let shipping = {
      firstname,
      lastname,
      address,
      city,
      email: userInfo.email,
      postalCode: zip,
      country,
      phone
    }

    // console.log("paymentResult 1 ==> ", paymentMethod)

    if (paymentResult.status === "PAID") {
      // console.log("PAYMENT PAID ====>", paymentResult, order);
      // console.log("PAYMENT PAID shipping ====>", shipping);
      // console.log("PAYMENT PAID shipping firstname ====>", shipping.firstname);
      // console.log("PAYMENT PAID paymentMethod ====>", paymentMethod);
      // console.log(
      //   "PAYMENT PAID JSON.parse(localStorage.getItem(shipping)) ====>",
      //   JSON.parse(localStorage.getItem("shipping"))
      // );
      // console.log(
      //   "PAYMENT PAID localStorage.getItem(method) ====>",
      //   localStorage.getItem("method")
      // );
      // dispatch(updateOrder(orderU, paymentResult));
      dispatch(
        createOrder({
          orderItems: cart,
          shippingAddress:
            shipping.firstname === undefined
              ? JSON.parse(localStorage.getItem("shipping"))
              : shipping,
          itemsPrice: data.itemsPrice,
          taxPrice: data.taxPrice,
          orderNo: orderNo,
          totalPrice: cartPriceTTC,
          paymentMethod:
            paymentMethod === ""
              ? localStorage.getItem("method")
              : paymentMethod
        })
      )
      swal(
        "achat effectué!"
        // "Nous espérons vous revoir bientôt.",
      ).then(() => {
        //   console.log("HELLO ===>", order);
        dispatch(deleteAllFromCart())
        localStorage.removeItem("shipping")
        localStorage.removeItem("method")
        setPaid(true)
      })
    } else if (paymentResult.status === "PENDING") {
      // console.log("PAYMENT PENDING ====>", shipping);
      // console.log("paymentResult 2 ==> ", paymentResult);

      localStorage.setItem("shipping", JSON.stringify(shipping))
      localStorage.setItem("method", paymentMethod)
    } else if (paymentResult.status === "FAILED") {
      // console.log("PAYMENT FAILED ====>");
      message.error("Paiement refusé")
    }
  }

  const onErrorPaypal = (error) =>
    console.log("Erroneous payment OR failed to load script!", error)

  const onCancelPaypal = (data) => console.log("Cancelled payment!", data)

  const handlePhone = (value) => {
    setPhone(value)
  }

  const handleCoupon = async () => {
    // > Sned to data base
    // console.log("COUPON DATA ===>", coupon, userInfo)

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token.token}`
        }
      }

      const response = await axios.post(
        `${process.env.REACT_APP_REST_API_URL}api/user/coupon`,
        { code: coupon },
        config
      )
      // console.log("DATA ==>", response)
      if (response) {
        setCartPriceTTC(
          (
            cartPriceTTC -
            (cartPriceTTC * response.data.data.discount) / 100
          ).toFixed(2)
        )
        setCoupon("")
        openNotificationWithIcon("success", "Coupon", "réduction appliquée")
      }
    } catch (error) {
      setCoupon("")
      openNotificationWithIcon("error", "Coupon", error.response.data.message)
    }
  }

  const Step1Form = () => {
    return (
      <>
        <div className="step">
          <div className="space-mb--20">
            {/* Billing Address */}
            <div id="billing-form" className="space-mb--40">
              <h4 className="mb-3">Informations de livraison:</h4>
              <div className="row">
                <div className="col-12 space-mb--20">
                  <label>
                    Nom <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Nom obligatoire"
                      }
                    ]}
                    name="firstname"
                  >
                    <Input
                      id="address"
                      className="form-control"
                      placeholder="Votre nom"
                      required
                    />
                  </Form.Item>
                </div>
                <div className="col-12 space-mb--20">
                  <label>
                    Prénom <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Prénom obligatoire"
                      }
                    ]}
                    name="lastname"
                  >
                    <Input
                      id="address"
                      className="form-control"
                      placeholder="Votre prénom"
                      required
                    />
                  </Form.Item>
                </div>
                <div className="col-12 space-mb--20">
                  <label>
                    Adresse <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Adresse obligatoire"
                      }
                    ]}
                    name="address"
                  >
                    <Input
                      id="address"
                      className="form-control"
                      placeholder="Votre adresse"
                      required
                    />
                  </Form.Item>
                </div>
                <div className="col-md-6 col-12 space-mb--20">
                  <label>
                    Ville <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Ville obligatoire"
                      }
                    ]}
                    name="city"
                  >
                    <Input
                      id="ville"
                      className="form-control"
                      placeholder="Votre ville"
                      required
                    />
                  </Form.Item>
                </div>
                <div className="col-md-6 col-12 space-mb--20">
                  <label>
                    Pays de résidence <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Pays obligatoire"
                      }
                    ]}
                    name="country"
                  >
                    <Input
                      id="pays"
                      className="form-control"
                      placeholder="votre pays"
                      required
                    />
                  </Form.Item>
                </div>
                <div className="col-md-6 col-12 space-mb--20">
                  <label>
                    Numero de telephone <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Téléphone obligatoire"
                      }
                    ]}
                    name="phone"
                  >
                    {/* <Input
                      id="phone"
                      className="form-control"
                      placeholder="phone"
                    /> */}
                    <PhoneInput
                      required
                      placeholder="Votre numéro de téléphone"
                      value={phone}
                      onChange={handlePhone}
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: true
                      }}
                      country={"fr"}
                      specialLabel="Numero"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-6 col-12 space-mb--20">
                  <label>
                    Code postal <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Code postal obligatoire"
                      }
                    ]}
                    name="zip"
                  >
                    <Input
                      id="zip"
                      className="form-control"
                      placeholder="votre code postal"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="text-center">
                <div className="single-method mb-3 mt-3">
                  <input
                    type="checkbox"
                    id="accept_terms"
                    onChange={(e) => setCheck(e.target.checked)}
                    checked={check}
                  />
                  <label htmlFor="accept_terms">
                    J'ai lu et j'accepte{" "}
                    <Link to="/condition" style={{ color: "black" }}>
                      les conditions génerales de ventes &amp; d'utilisations{" "}
                    </Link>
                    <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const StepEnd = () => {
    const { address, zip, city, country, firstname, lastname } =
      form.getFieldsValue()

    return (
      <>
        <div className="submit step" id="end">
          <h4 className="section_title">Paiement </h4>
          <div className="summary">
            <div className="wrapper">
              <div className="site-page-header-ghost-wrapper">
                <PageHeader
                  ghost={false}
                  onBack={() => prev()}
                  title="Modifier"
                  subTitle="mon address de livraison"
                >
                  <Descriptions size="small" column={2}>
                    <Descriptions.Item label="Nom">
                      {firstname}
                    </Descriptions.Item>
                    <Descriptions.Item label="Prénom">
                      {lastname}
                    </Descriptions.Item>
                    <Descriptions.Item label="Code postale">
                      {zip}
                    </Descriptions.Item>
                    <Descriptions.Item label="Pays">
                      {country}
                    </Descriptions.Item>
                    <Descriptions.Item label="Ville">{city}</Descriptions.Item>
                    <Descriptions.Item label="N° Téléphone">
                      {phone}
                    </Descriptions.Item>
                    <Descriptions.Item label="Adresse">
                      {address}
                    </Descriptions.Item>
                  </Descriptions>
                </PageHeader>
              </div>
              <div className="mt-3">
                {/* <PaypalButton
                  onSuccess={successPaypal}
                  total={data.totalPrice}
                  onError={onErrorPaypal}
                  onCancel={onCancelPaypal}
                /> */}
                <h4 className="">Choisir un moyen de paiement</h4>
                <div className="checkout-payment-method">
                  <div className="single-method">
                    <input
                      type="radio"
                      id="payment_stripe"
                      name="payment-method"
                      value="cb"
                      checked={paymentMethod === "cb"}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    />
                    <label htmlFor="payment_stripe">
                      <img src="/images/cart.png" alt="" />
                    </label>
                  </div>
                  <div className="single-method">
                    <input
                      type="radio"
                      id="payment_paypal"
                      name="payment-method"
                      value="payPal"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      checked={paymentMethod === "payPal"}
                    />
                    <label htmlFor="payment_paypal">
                      <img src="/images/paypal.png" alt="" width="55px" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const steps = [
    {
      step: 1,
      title: "Informations",
      content: <Step1Form />
    },
    { step: 2, title: "Paiement", content: <StepEnd /> }
  ]

  // setCartPriceTTC(data.totalPrice);

  //   cartPriceHT +=
  //   product.price * product.quantity;
  // cartPriceTVA +=
  //   product.price * product.quantity * 0.2;
  // cartPriceTTC = cartPriceTVA + cartPriceHT;

  const orderNo = `LVH-${dayjs().year()}${dayjs().month()}${dayjs().minute()}-${Math.random()
    .toString(36)
    .slice(2)
    .toUpperCase()}`

  return (
    <LayoutFive>
      {/* breadcrumb */}

      <Banner
        title="Processus de paiement"
        image="/images/banners/banner.jpg"
        link="checkout"
      />

      <div className="checkout-area space-mt--r130 space-mb--r130">
        <Container>
          {cartItems && cartItems.length >= 1 ? (
            <Row>
              <Col>
                <div className="lezada-form">
                  <Form
                    form={form}
                    className="checkout-form"
                    initialValues={{ remember: true }}
                  >
                    <div className="row row-40">
                      <div className="col-lg-7 space-mb--20 pl-5 pr-5">
                        {/* Billing Address */}

                        <div id="wizard_container">
                          <Steps current={current}>
                            {steps.map((item) => (
                              <Step key={item.title} title={item.title} />
                            ))}
                          </Steps>
                          {steps.map((item) => (
                            <div
                              key={item.step}
                              className={`steps-content mt-5 ${
                                item.step !== current + 1 && "hidden"
                              }`}
                            >
                              {item.content}
                            </div>
                          ))}

                          <div id="bottom-wizard">
                            {current < steps.length - 1 && (
                              <button
                                type="button"
                                onClick={() => next()}
                                className="lezada-button lezada-button--medium space-mt--20"
                              >
                                Suivant
                              </button>
                            )}
                            {/* {current === steps.length - 1 && (
                                <Button
                                  className="lezada-button lezada-button--medium space-mt--20"
                                  htmlType="submit"
                                  // onClick={() => message.success("Processing complete!")}
                                >
                                  Done
                                </Button>
                              )} */}
                            {current > 0 && (
                              <button
                                // style={{ margin: "0 8px" }}
                                onClick={() => prev()}
                                className="lezada-button lezada-button--medium space-mt--20 mt-4"
                              >
                                Précédent
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <div className="row">
                          {/* Cart Total */}
                          <div className="col-12 space-mb--50">
                            <div className="checkout-cart-total">
                              <div className="col-12">
                                <h4>
                                  Mes produits <span>Total</span>
                                </h4>
                                <ul>
                                  {cartItems.map((product, i) => {
                                    const discountedPrice = getDiscountPrice(
                                      product.price,
                                      product.discount
                                    ).toFixed(2)

                                    // cartPriceHT +=
                                    //   product.price * product.quantity;
                                    // cartPriceTVA +=
                                    //   product.price * product.quantity * 0.2;
                                    // // setCartPriceTVA()
                                    // // totalFrais += product.frais;
                                    // cartPriceTTC = cartPriceTVA + cartPriceHT;

                                    return (
                                      <div key={i}>
                                        <li key={i}>
                                          <img
                                            src={product.image[0]}
                                            width="30px"
                                            alt=""
                                            className="mr-3"
                                          />
                                          {product.name}
                                          {/* {product.selectedProductColor &&
                                          product.selectedProductSize ? (
                                            <div className="product-variation">
                                              <span>
                                                Couleur:{" "}
                                                {product.selectedProductColor}
                                              </span>
                                              <span>
                                                Taille:{" "}
                                                {product.selectedProductSize}
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )} */}
                                          X {product.quantity}
                                          <span>{discountedPrice} €</span>
                                        </li>
                                      </div>
                                    )
                                  })}
                                </ul>
                                {/* <p>
                                  Prix HT
                                  <span>{cartPriceHT} €</span>
                                </p>
                                <p>
                                  TVA
                                  <span>{cartPriceTVA} €</span>
                                </p> */}
                                <p>
                                  Frais de livraison <span>4.50 €</span>
                                </p>
                                <h4>
                                  Prix TTC
                                  <span>{cartPriceTTC} €</span>
                                </h4>
                              </div>
                              {current === steps.length - 1 && (
                                // <button htmltype="submit">
                                <>
                                  <div className="col-12 space-mt--20">
                                    <label>Code promo</label>

                                    <input
                                      id="coupon"
                                      value={coupon}
                                      className="form-control"
                                      placeholder="Votre code"
                                      onChange={(e) =>
                                        setCoupon(e.target.value)
                                      }
                                      required
                                    />

                                    <button
                                      // style={{ margin: "0 8px" }}
                                      onClick={() => handleCoupon()}
                                      className="lezada-button lezada-button--small"
                                    >
                                      Appliquer
                                    </button>
                                  </div>
                                </>
                              )}

                              <div className="col-12 mt-5">
                                {current === steps.length - 1 && (
                                  // <button htmltype="submit">
                                  <>
                                    {paymentMethod === "cb" ? (
                                      <SumpupButton
                                        amount={cartPriceTTC}
                                        onSuccess={successSumup}
                                        checkoutId={checkoutId}
                                        current={current}
                                        paymentMethod={paymentMethod}
                                      />
                                    ) : paymentMethod === "payPal" ? (
                                      <PaypalButton
                                        onSuccess={successPaypal}
                                        total={cartPriceTTC}
                                        onError={onErrorPaypal}
                                        onCancel={onCancelPaypal}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          ) : paid === true ? (
            <Row>
              <Col>
                <div className="item-empty-area text-center">
                  <div className="item-empty-area__icon space-mb--30">
                    <IoIosCheckmarkCircleOutline />
                  </div>
                  <div className="item-empty-area__text">
                    <p className="space-mb--30">
                      Votre commande a bien été enregistrée
                    </p>
                    <p className="">
                      Nous vous remercions pour votre commande et espérons que
                      vous avez apprécié votre shopping sur notre site. Vous
                      allez recevoir un e-mail de confirmation Bientot
                    </p>
                    <Link
                      //   href="/shop/left-sidebar"
                      to={`/order/${order && order._id}`}
                      className="lezada-button lezada-button--medium"
                    >
                      Voir le recapitulatif
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <div className="item-empty-area text-center">
                  <div className="item-empty-area__icon space-mb--30">
                    <IoMdCash />
                  </div>
                  <div className="item-empty-area__text">
                    <p className="space-mb--30">Aucun produit</p>

                    <Link
                      //   href="/shop/left-sidebar"
                      to={`/shop`}
                      className="lezada-button lezada-button--medium"
                    >
                      La boutique
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </LayoutFive>
  )
}

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData
  }
}

export default connect(mapStateToProps)(Checkout)
