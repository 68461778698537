import React, { Fragment, useState } from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosHeartEmpty, IoIosShuffle, IoIosSearch } from "react-icons/io";
import { Tooltip } from "react-tippy";
import ProductModal from "./ProductModal";

const ProductGridList = ({
  product,
  discountedPrice,
  productPrice,
  cartItem,
  wishlistItem,
  compareItem,
  bottomSpace,
  addToCart,
  addToWishlist,
  deleteFromWishlist,
  addToCompare,
  deleteFromCompare,
  addToast,
  cartItems,
}) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <Fragment>
      <Col lg={3} md={6} className={bottomSpace ? bottomSpace : ""}>
        <div className="product-grid">
          {/*=======  single product image  =======*/}
          <div className="product-grid__image">
            <Link
              // href={`/shop/product-basic/[slug]?slug=${product.slug}`}
              to={`/product-detail/${product.slug}?id=${product.id}`}
              // to={{ pathname: `/product-detail/${product.slug}?id=${product.id}` , query: { the: 'query' } }}

              className="image-wrap"
            >
              <img
                src={product.image[0]}
                className="img-fluid"
                alt={product.name}
              />
              {product.image.length > 1 ? (
                <img
                  src={product.image[1]}
                  className="img-fluid"
                  alt={product.name}
                />
              ) : (
                ""
              )}
            </Link>
            <div className="product-grid__floating-badges">
              {product.discount && product.discount > 0 ? (
                <span className="onsale">-{product.discount}%</span>
              ) : (
                ""
              )}
              {product.new ? <span className="hot">Nouveau</span> : ""}
              {/* {product.stock === 0 ? (
                <span className="out-of-stock">Epuisé</span>
              ) : (
                ""
              )} */}
            </div>
            <div className="product-grid__floating-icons">
              {/* add to wishlist */}
              <Tooltip
                title={
                  wishlistItem !== undefined
                    ? "Dans mes moeux"
                    : "Ajouter à mes voeux"
                }
                position="left"
                trigger="mouseenter"
                animation="shift"
                arrow={true}
                duration={200}
              >
                <button
                  onClick={
                    wishlistItem !== undefined
                      ? () => deleteFromWishlist(product, addToast)
                      : () => addToWishlist(product, addToast)
                  }
                  className={wishlistItem !== undefined ? "active" : ""}
                >
                  <IoIosHeartEmpty />
                </button>
              </Tooltip>

              {/* add to compare */}
              {/* <Tooltip
                title={
                  compareItem !== undefined
                    ? "Added to compare"
                    : "Add to compare"
                }
                position="left"
                trigger="mouseenter"
                animation="shift"
                arrow={true}
                duration={200}
              >
                <button
                  onClick={
                    compareItem !== undefined
                      ? () => deleteFromCompare(product, addToast)
                      : () => addToCompare(product, addToast)
                  }
                  className={compareItem !== undefined ? "active" : ""}
                >
                  <IoIosShuffle />
                </button>
              </Tooltip> */}

              {/* quick view */}
              {/* <Tooltip
                title="Quick view"
                position="left"
                trigger="mouseenter"
                animation="shift"
                arrow={true}
                duration={200}
              >
                <button
                  onClick={() => setModalShow(true)}
                  className="d-none d-lg-block"
                >
                  <IoIosSearch />
                </button>
              </Tooltip> */}
            </div>
          </div>

          {/*=======  single product content  =======*/}
          <div className="product-grid__content">
            <div className="title" style={{ textAlign: "left" }}>
              <h3>
                <Link
                  // href={`/shop/product-basic/[slug]?slug=${product.slug}`}
                  to={
                    process.env.PUBLIC_URL +
                    "/shop/product-basic/" +
                    product.slug
                  }
                >
                  {product.name.charAt(0).toUpperCase() + product.name.slice(1)}
                </Link>
              </h3>
              {/* add to cart */}
              {product.affiliateLink ? (
                <a href={product.affiliateLink} target="_blank">
                  Buy now
                </a>
              ) : product.variation && product.variation.length >= 1 ? (
                <Link
                  // href={`/shop/product-basic/[slug]?slug=${product.slug}`}
                  to={`/product-detail/${product.slug}?id=${product.id}`}
                >
                  Choisir une option
                </Link>
              ) : product.stock && product.stock > 0 ? (
                <button
                  onClick={() => addToCart(product, addToast)}
                  disabled={
                    cartItem !== undefined &&
                    cartItem.quantity >= cartItem.stock
                  }
                >
                  {cartItem !== undefined
                    ? "Dans mon panier"
                    : "Ajouter au panier"}
                </button>
              ) : (
                <button disabled>Epuisé</button>
              )}
            </div>
            <div className="price">
              {product.discount > 0 ? (
                <Fragment>
                  <span className="main-price discounted">
                    {productPrice} €
                  </span>
                  <span className="discounted-price">{discountedPrice} €</span>
                </Fragment>
              ) : (
                <span className="main-price">{productPrice} €</span>
              )}
            </div>
          </div>
        </div>

        <div className="product-list">
          {/*=======  single product image  =======*/}
          <div className="product-list__image">
            <Link
              // href={`/shop/product-basic/[slug]?slug=${product.slug}`}
              to={
                process.env.PUBLIC_URL + "/shop/product-basic/" + product.slug
              }
              className="image-wrap"
            >
              <img
                src={product.image[0]}
                className="img-fluid"
                alt={product.name}
              />
              {product.image.length > 1 ? (
                <img
                  src={product.image[1]}
                  className="img-fluid"
                  alt={product.name}
                />
              ) : (
                ""
              )}
            </Link>
            <div className="product-list__floating-badges">
              {product.discount && product.discount > 0 ? (
                <span className="onsale">-{product.discount}%</span>
              ) : (
                ""
              )}
              {product.new ? <span className="hot">Nouveau</span> : ""}
              {product.stock === 0 ? (
                <span className="out-of-stock">Epuisé</span>
              ) : (
                ""
              )}
            </div>
            <div className="product-list__floating-icons">
              {/* add to wishlist */}
              <Tooltip
                title={
                  wishlistItem !== undefined
                    ? "Dans mes moeux"
                    : "Ajouter à mes voeux"
                }
                position="left"
                trigger="mouseenter"
                animation="shift"
                arrow={true}
                duration={200}
              >
                <button
                  onClick={
                    wishlistItem !== undefined
                      ? () => deleteFromWishlist(product, addToast)
                      : () => addToWishlist(product, addToast)
                  }
                  className={wishlistItem !== undefined ? "active" : ""}
                >
                  <IoIosHeartEmpty />
                </button>
              </Tooltip>

              {/* add to compare */}
              <Tooltip
                title={
                  compareItem !== undefined
                    ? "Added to compare"
                    : "Add to compare"
                }
                position="left"
                trigger="mouseenter"
                animation="shift"
                arrow={true}
                duration={200}
              >
                <button
                  onClick={
                    compareItem !== undefined
                      ? () => deleteFromCompare(product, addToast)
                      : () => addToCompare(product, addToast)
                  }
                  className={compareItem !== undefined ? "active" : ""}
                >
                  <IoIosShuffle />
                </button>
              </Tooltip>

              {/* quick view */}
              {/* <Tooltip
                title="Quick view"
                position="left"
                trigger="mouseenter"
                animation="shift"
                arrow={true}
                duration={200}
              >
                <button
                  onClick={() => setModalShow(true)}
                  className="d-none d-lg-block"
                >
                  <IoIosSearch />
                </button>
              </Tooltip> */}
            </div>
          </div>

          {/*=======  single product content  =======*/}
          <div className="product-list__content">
            <div className="title" style={{ textTransform: "lowercase" }}>
              <h3>
                <Link
                  // href={`/shop/product-basic/[slug]?slug=${product.slug}`}
                  to={
                    process.env.PUBLIC_URL +
                    "/shop/product-basic/" +
                    product.slug
                  }
                >
                  {product.name}
                </Link>
              </h3>
            </div>
            <div className="price">
              {product.discount > 0 ? (
                <Fragment>
                  <span className="main-price discounted">
                    {productPrice} €
                  </span>
                  <span className="discounted-price">{discountedPrice} €</span>
                </Fragment>
              ) : (
                <span className="main-price">{productPrice} €</span>
              )}
            </div>

            <div className="short-description">{product.shortDescription}</div>
            <div className="add-to-cart">
              {/* add to cart */}
              {product.affiliateLink ? (
                <a
                  href={product.affiliateLink}
                  target="_blank"
                  className="lezada-button lezada-button--medium"
                >
                  Buy now
                </a>
              ) : product.variation && product.variation.length >= 1 ? (
                <Link
                  // href={`/shop/product-basic/[slug]?slug=${product.slug}`}
                  to={`/product-detail/${product.slug}?id=${product.id}`}
                  className="lezada-button lezada-button--medium"
                >
                  Select Option
                </Link>
              ) : product.stock && product.stock > 0 ? (
                <button
                  onClick={() => addToCart(product, addToast)}
                  disabled={
                    cartItem !== undefined &&
                    cartItem.quantity >= cartItem.stock
                  }
                  className="lezada-button lezada-button--medium"
                >
                  {cartItem !== undefined
                    ? "Dans mon panier"
                    : "Ajouter au panier"}
                </button>
              ) : (
                <button
                  disabled
                  className="lezada-button lezada-button--medium"
                >
                  Epuisé
                </button>
              )}
            </div>
          </div>
        </div>
      </Col>
      {/* product modal */}
      <ProductModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        discountedprice={discountedPrice}
        productprice={productPrice}
        cartitems={cartItems}
        cartitem={cartItem}
        wishlistitem={wishlistItem}
        compareitem={compareItem}
        addtocart={addToCart}
        addtowishlist={addToWishlist}
        deletefromwishlist={deleteFromWishlist}
        addtocompare={addToCompare}
        deletefromcompare={deleteFromCompare}
        addtoast={addToast}
      />
    </Fragment>
  );
};

export default ProductGridList;
