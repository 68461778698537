import React, { useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { useHistory } from "react-router-dom";

const Search = ({ history }) => {
  const [keyword, setKeyword] = useState();
  // const history = useHistory()

  const handleSubmmit = (e) => {
    e.preventDefault();
    if (keyword) {
      history.push(`/search/${keyword.trim()}`);
    } else {
      history.push("/shop");
    }
  };

  return (
    <div className="single-sidebar-widget space-mb--40">
      {/* search widget */}
      <div className="search-widget" style={{ border: "none" }}>
        <form onSubmit={handleSubmmit}>
          <input
            onChange={(e) => setKeyword(e.target.value)}
            type="text"
            name="q"
            placeholder="Recherchez un produit ..."
          />
          <button type="submit">
            <IoIosSearch />
          </button>
        </form>
      </div>
    </div>
  );
};

export default Search;
