import React, { useState } from "react";
import Banner from "../../components/Banner/Banner";
import { LayoutFive } from "../../components/Layout";
import { ContainHome } from "../home/home.style";

import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { getDiscountPrice } from "../../lib/product";
import { addToCart } from "../../redux/actions/cartActions";
import {
  addToWishlist,
  deleteFromWishlist,
} from "../../redux/actions/wishlistActions";
import { Col, Container, Row } from "react-bootstrap";
import {
  addToCompare,
  deleteFromCompare,
} from "../../redux/actions/compareActions";

import { IoIosCart } from "react-icons/io";
import { Fade } from "react-reveal";
import { useMediaQuery } from "@material-ui/core";
import Anchor from "../../components/anchor";

const Book = ({
  bottomSpace,
  addToCart,
  addToWishlist,
  deleteFromWishlist,
  addToCompare,
  deleteFromCompare,
  cartItems,
  wishlistItems,
}) => {
  const { addToast } = useToasts();
  const [modalShow, setModalShow] = useState(false);

  const [product, setProduct] = useState({
    fullDescription:
      '<p><span style="color: rgb(189,193,198);background-color: rgb(32,33,36);font-size: 14px;font-family: arial, sans-serif;">Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu\'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.</span></p>\n',
    id: "a7cab60b-6b83-4df2-8b9d",
    name: "il le fera pour toi aussi",
    slug: "book",
    image: [
      "https://res.cloudinary.com/df0amuzcb/image/upload/v1676404712/clarisse_of7rqp.png",
    ],
    unit: "1",
    category: [],
    tag: [],
    price: 19.9,
    new: false,
    activated: true,
    discount: 0,
    per_unit: 1,
    stock: 1000000000,
    description:
      '<p><span style="color: rgb(189,193,198);background-color: rgb(32,33,36);font-size: 14px;font-family: arial, sans-serif;">Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu\'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.</span></p>\n',
  });

  const discountedPrice = getDiscountPrice(
    product.price,
    product.discount
  ).toFixed(2);
  const productPrice = product.price.toFixed(2);
  const cartItem = cartItems.filter(
    (cartItem) => cartItem.id === product.id
  )[0];
  const wishlistItem = wishlistItems.filter(
    (wishlistItem) => wishlistItem.id === product.id
  )[0];

  return (
    <LayoutFive>
      <ContainHome>
        <Banner
          title="Mon livre"
          image="/images/banner-book-2.png"
          link="livre"
        />
        <Container style={{ textAlign: "center" }} id="book-page">
          <Row style={{ marginBottom: "75px" }}>
            <Col lg={12} md={12} className={bottomSpace ? bottomSpace : ""}>
              <Fade left>
                <div style={{ marginTop: "50px", textDecoration: "justify" }}>
                  <div className="image-cta-two__content space-mb-mobile-only--40">
                    <h1
                      className="space-mb--30"
                      style={{ color: "#d4ba84", fontSize: "35px" }}
                    >
                      À propos de
                    </h1>
                    <h3
                      className="book-title"
                      style={{
                        fontFamily: "timberline",
                        wordSpacing: "14px",
                        margin: "auto",
                        textAlign: "center",
                        maxWidth: "500px",
                        marginBottom: "100px",
                      }}
                    >
                      <span style={{ display: "block" }}>
                        Il l’a fait pour moi, Il le fera
                      </span>
                      <span style={{ display: "block" }}>pour toi aussi</span>
                    </h3>
                    <p
                      className="text"
                      style={{
                        textAlign: "justify",
                        fontSize: "20px",
                        marginBottom: "25px",

                        fontWeight: "300",
                      }}
                    >
                      <div
                        className="dots-image"
                        style={{
                          float: "right",
                          marginLeft: "5%",
                        }}
                      >
                        <img
                          alt=""
                          className="img-responsive img-fluid"
                          width="350px"
                          src="/images/clarisse.png"
                        />
                        <div className="dots"></div>
                      </div>
                      Avez-vous remarqué à quel point le mariage intrigue dès le
                      plus jeune âge… On voit assez souvent les tous petits
                      s’essayer au rôle de la mariée et du marié comme si
                      c’était quelque chose de naturel qu’il y avait deja en
                      eux.
                    </p>
                    <p
                      className="text"
                      style={{
                        marginBottom: "25px",
                        fontWeight: "300",
                        textAlign: "justify",
                        fontSize: "20px",
                      }}
                    >
                      Je me suis toujours posé la question : pourquoi le mariage
                      occupe t-il notre esprit ainsi et qu’inspire t-il
                      réellement ? Mais j’ai également constaté que le mariage
                      peut être source de tension et de division au sein d’une
                      même famille, les générations passées et leurs traditions,
                      que signifie exactement le mariage ?
                    </p>
                    <p
                      className="text"
                      style={{
                        marginBottom: "25px",
                        fontWeight: "300",
                        textAlign: "justify",
                        fontSize: "20px",
                      }}
                    >
                      Comment des conflits peuvent-ils apparaître au point de
                      déchirer, blesser et parsemer toute une famille ?
                    </p>
                    <p
                      className="text"
                      style={{
                        marginBottom: "25px",
                        fontWeight: "300",
                        textAlign: "justify",
                        fontSize: "20px",
                      }}
                    >
                      Autant de questions que suscitent le sujet du « mariage »
                      et autant de questions que je me suis posées.
                    </p>
                    <p
                      className="text"
                      style={{
                        marginBottom: "25px",
                        fontWeight: "300",
                        textAlign: "justify",
                        fontSize: "20px",
                      }}
                    >
                      J’aime à dire que le mariage est comme un voyage que l’on
                      accepte d’entreprendre à deux pour une durée indéterminée.
                      Un voyage où chacun a appris à être heureux seul, avant de
                      faire de l’être a deux.
                    </p>{" "}
                    <p
                      className="text"
                      style={{
                        marginBottom: "25px",
                        fontWeight: "300",
                        textAlign: "justify",
                        fontSize: "20px",
                      }}
                    >
                      Un voyage où le «<strong>Je</strong>» laisse la place au «
                      <strong>Nous</strong>», où l’on apprend au préalable à
                      faire de la place physiquement, émotionnellement et
                      spirituellement à l’autre.
                    </p>
                    <p
                      className="text"
                      style={{
                        marginBottom: "25px",
                        fontWeight: "300",
                        textAlign: "justify",
                        fontSize: "20px",
                      }}
                    >
                      Je vous emmène avec moi pour vous raconter une étape de
                      mon histoire…
                    </p>
                    <p
                      className="text"
                      style={{
                        marginBottom: "25px",
                        fontWeight: "300",
                        textAlign: "justify",
                        fontSize: "20px",
                      }}
                    >
                      Bienvenue dans « Il l’a fait pour moi, Il le fera pour toi
                      aussi »
                    </p>
                  </div>
                </div>
              </Fade>
            </Col>

            <Col
              lg={12}
              style={{
                marginTop: "50px",
              }}
            >
              <Row className="no-gutters">
                <Col lg={6}>
                  <div
                    className="about-bg-content"
                    style={{
                      backgroundColor: "#d4ba84",
                      textAlign: "center",
                    }}
                  >
                    <div className="">
                      {/*=======  single product content  =======*/}
                      <div className="">
                        <div>
                          <h2
                            className="section-title"
                            style={{ fontFamily: "timberline" }}
                          >
                            Venez avec moi !
                          </h2>
                          <h6 style={{ color: "white", fontStyle: "italic" }}>
                            Tout commence ici ...
                          </h6>
                        </div>
                        <h6 className="text space-mb--10">
                          Pour commander le livre
                        </h6>
                        <div className="add-to-cart">
                          {/* add to cart */}
                          {product.stock && product.stock > 0 ? (
                            <button
                              onClick={() => addToCart(product, addToast)}
                              disabled={
                                cartItem !== undefined &&
                                cartItem.quantity >= cartItem.stock
                              }
                              className="lezada-button lezada-button--medium"
                            >
                              <IoIosCart />
                              {cartItem !== undefined
                                ? "Dans mon panier"
                                : "Ajouter au panier"}
                            </button>
                          ) : (
                            <button
                              onClick={() => addToCart(product, addToast)}
                              disabled={
                                cartItem !== undefined &&
                                cartItem.quantity >= cartItem.stock
                              }
                              className="lezada-button lezada-button--medium"
                            >
                              <IoIosCart />
                              {cartItem !== undefined
                                ? "Dans mon panier"
                                : "Ajouter au panier"}
                            </button>
                          )}
                        </div>
                        <h6
                          className="text"
                          style={{ textAlign: "center", marginBottom: "100px" }}
                        >
                          <span
                            style={{
                              color: "white",
                              fontWeight: 900,
                              marginRight: "10px",
                            }}
                          >
                            19,90 €
                          </span>
                          au lieu de <del>23,90 €</del>
                        </h6>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div
                    className="about-bg-background"
                    style={{
                      backgroundImage: `url("./images/me.jpeg")`,
                      backgroundPosition: "25% 25%",
                    }}
                  ></div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </ContainHome>
    </LayoutFive>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData,
    wishlistItems: state.wishlistData,
    compareItems: state.compareData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (
      item,
      addToast,
      quantityCount,
      selectedProductColor,
      selectedProductSize
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
          selectedProductColor,
          selectedProductSize
        )
      );
    },
    addToWishlist: (item, addToast) => {
      dispatch(addToWishlist(item, addToast));
    },
    deleteFromWishlist: (item, addToast) => {
      dispatch(deleteFromWishlist(item, addToast));
    },
    addToCompare: (item, addToast) => {
      dispatch(addToCompare(item, addToast));
    },
    deleteFromCompare: (item, addToast) => {
      dispatch(deleteFromCompare(item, addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Book);
