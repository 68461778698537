import React, { PureComponent, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next";

const Navigation = () => {
  const { t } = useTranslation();

  // if (window.location.pathname !== "/" && window.scrollY < 10) {
  //   return <div></div>;
  // } else {
  return (
    <nav
      className="header-content__navigation space-pr--15 space-pl--15 d-none d-lg-block"
      id="header-content__navigation"
      style={{
        fontFamily: "proxima",
      }}
    >
      <ul>
        <li>
          <Link href="/" to="">
            {t("navigation.home")}
          </Link>
        </li>
        <li>
          <Link to="/gallery">{t("navigation.shop")}</Link>
        </li>
        <li>
          <Link to="/service">{t("navigation.service")}</Link>
        </li>
        {/* <li className="position-relative"> */}
        {/* <Link href="/" to={process.env.PUBLIC_URL + "/"}>
              Pages
            </Link> */}
        {/* </li> */}
        <li className="position-relative">
          <Link to="/project">Mes projets</Link>
        </li>
        <li className="position-relative">
          <Link to="/book">Mon livre</Link>
        </li>
        <li className="position-relative">
          <Link to="/contact">{t("navigation.contact")}</Link>
        </li>
      </ul>
    </nav>
  );
  // }
};

export default Navigation;
