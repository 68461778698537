import React, { useEffect } from "react";
import { Fade } from "react-reveal";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.getElementById("presentation").innerHTML = t("about.text");
  });
  return (
    <div>
      <section id="about" className="section section-about">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <strong
                className="section-subtitle"
                style={{
                  fontFamily: "theano",
                  fontSize: "35px",
                  fontWeight: "bold",
                }}
              >
                {t("about.title")}
              </strong>
              <h2
                className="section-title section-about-title"
                style={{
                  fontFamily: "theano",
                  fontSize: "25px",
                  paddingTop: "15px",
                }}
              >
                {t("about.subtitle")}
              </h2>

              <Fade left>
                <p
                  style={{
                    fontSize: "18px",
                    fontFamily: "theano",
                    lineHeight: "30px",
                  }}
                  id="presentation"
                ></p>
              </Fade>
              {/* <div className="experience-box">
                    <div className="experience-border"></div>
                    <div className="experience-content">
                      
                    </div>
                  </div> */}
            </div>

            <div
              className="col-md-6"
              style={{
                marginTop: "10%",
              }}
            >
              <div className="dots-image">
                <Fade right>
                  <img
                    alt=""
                    className="about-img img-responsive mt-5 img-fluid"
                    src="/images/about.jpeg"
                  />
                  <div className="dots"></div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
