import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from "i18next-xhr-backend";

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    loadPath: './locales/{{lng}}/{{ns}}.json',
    lng: "fr",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;