import React, { useEffect, useState } from "react";
import { LayoutFive } from "../../../components/Layout";
import { useTranslation } from "react-i18next";
import Banner from "../../../components/Banner/Banner";
import Axios from "axios";
import projects from "../../../data/carousel/carousel.json";
import { CardDeck, Col } from "react-bootstrap";
import { Image } from "antd";
import { Fade } from "react-reveal";
import { useParams } from "react-router-dom";

const SingleProject = () => {
  // const { t } = useTranslation();
  const { id } = useParams();
  const [project, setProject] = useState();

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_REST_API_URL}api/other/project/${id}`
    ).then((response) => {
      setProject(response.data);
    });
  }, []);

  return (
    <LayoutFive>
      <Banner
        title="Détail du projet"
        image="/images/banners/banner-shop.jpg"
      />
      <div className="container">
        <section
          className="container"
          style={{
            marginTop: "100px",
          }}
        >
          <div className="o-container">
            <div className="o-layout">
              <div className="o-layout_item u-mt-20__768 u-mt-15__360">
                {/* <h5 className="c-banner-title__top">Vancouver, Canada</h5> */}
                <div className="c-banner_wrapper">
                  <Fade top>
                    <h1
                      className=""
                      style={{
                        fontFamily: "theano",
                        fontSize: "60px",
                      }}
                    >
                      {project && project.name}
                    </h1>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container">
          <div
            className="row"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "50px",
            }}
          >
            <div className="col-md-6">
              <h2
                className=""
                style={{
                  fontSize: "40px",
                  fontFamily: "theano",
                }}
              >
                Description
              </h2>
            </div>
            <div className="col-md-6">
              <h3
                className=""
                style={{
                  fontFamily: "proxima",
                  fontSize: "1.2em",
                  lineHeight: "25px",
                }}
              >
                {project && project.description}
              </h3>
            </div>
          </div>
        </section>
        <section
          className="container"
          style={{
            marginTop: "100px",
          }}
        >
          <div className="row">
            {project &&
              project.image.map((el, item) => (
                <Col md={6} className="mb-5" key={item}>
                  <Fade left>
                    <Image.PreviewGroup>
                      <Image
                        src={el && el.url}
                        alt=""
                        // width="500px"
                        className="img-fluid"
                      />
                    </Image.PreviewGroup>
                  </Fade>
                </Col>
              ))}
          </div>
        </section>
      </div>
    </LayoutFive>
  );
};

export default SingleProject;
