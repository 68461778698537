import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { getDiscountPrice } from "../../lib/product";
import { IoIosArrowBack, IoMdCash } from "react-icons/io";
import { LayoutFive } from "../../components/Layout";
import { BreadcrumbOne } from "../../components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { savePaymentMethod } from "../../redux/actions/orderActions";
import { useToasts } from "react-toast-notifications";

const Order = ({ cartItems }) => {
  let cartPriceHT = 0;
  let cartPriceTVA = 0;
  let cartPriceTTC = 0;

  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const history = useHistory();

  const shipping = useSelector((state) => state.shippingAddress);
  const orderCreate = useSelector((state) => state.orderCreate);

  const { shippingAddress } = shipping;
  const { order, success, error } = orderCreate;

  const [address, setAddress] = useState(
    shippingAddress ? shippingAddress.address : ""
  );
  const [phone, setPhone] = useState(
    shippingAddress ? shippingAddress.phone : ""
  );
  const [city, setCity] = useState(shippingAddress ? shippingAddress.city : "");
  const [country, setCountry] = useState(
    shippingAddress ? shippingAddress.country : ""
  );
  const [zip, setZip] = useState(shippingAddress ? shippingAddress.zip : "");

  const [paymentMethod, setPaymentMethod] = useState("payPal");
  const [accept, setAccept] = useState(false);

  if (!shippingAddress.address) {
    history.push("/shipping");
  }

  const placeOrderHandler = (e) => {
    e.preventDefault();
    if (!accept) {
      addToast("You have to accept conditions", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    dispatch(savePaymentMethod(order, paymentMethod));
    history.push(`/order/${order._id}`);
  };

  return (
    <LayoutFive>
      {/* breadcrumb */}
      <BreadcrumbOne
        pageTitle="Checkout"
        backgroundImage="/images/backgrounds/breadcrumb-bg-1.png"
      >
        <ul className="breadcrumb__list">
          <li>
            <Link href="/" to="/">
              Home
            </Link>
          </li>

          <li>Order</li>
        </ul>
      </BreadcrumbOne>
      <div className="checkout-area space-mt--r130 space-mb--r130">
        <Container>
          {cartItems && cartItems.length >= 1 ? (
            <Row>
              <Col>
                <div className="lezada-form">
                  <div className="checkout-form">
                    <div className="row row-40">
                      <div className="col-lg-7 space-mb--20">
                        <div id="billing-form" className="space-mb--40">
                          <h4 className="checkout-title">Order Summary :</h4>
                          <div className="row">
                            <div className="col-12 space-mb--20">
                              <label>Address*</label>
                              <input
                                type="text"
                                placeholder="Address line 1"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                disabled
                                required
                              />
                            </div>

                            <div className="col-md-6 col-12 space-mb--20">
                              <label>Town/City*</label>
                              <input
                                type="text"
                                placeholder="Town/City"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                disabled
                                required
                              />
                            </div>
                            <div className="col-md-6 col-12 space-mb--20">
                              <label>Country*</label>
                              <input
                                type="text"
                                placeholder="State"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                disabled
                                required
                              />
                            </div>
                            <div className="col-md-6 col-12 space-mb--20">
                              <label>Phone no*</label>
                              <input
                                type="text"
                                placeholder="Phone number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                                disabled
                              />
                            </div>
                            <div className="col-md-6 col-12 space-mb--20">
                              <label>Zip Code*</label>
                              <input
                                type="text"
                                placeholder="Zip Code"
                                value={zip}
                                onChange={(e) => setZip(e.target.value)}
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <div className="row">
                          {/* Cart Total */}
                          <div className="col-12 space-mb--50">
                            <h4 className="checkout-title">Total Panier</h4>
                            <div className="checkout-cart-total">
                              <h4>
                                Product <span>Total</span>
                              </h4>
                              <ul>
                                {cartItems.map((product, i) => {
                                  const discountedPrice = getDiscountPrice(
                                    product.price,
                                    product.discount
                                  ).toFixed(2);

                                  cartPriceHT +=
                                    discountedPrice * product.quantity;
                                  cartPriceTVA +=
                                    product.price * product.quantity * 0.2;
                                  // cartPriceTTC = cartPriceTVA + cartPriceHT;
                                  cartPriceTTC = cartPriceHT + 4.5;
                                  return (
                                    <div key={i}>
                                      <li key={i}>
                                        <img
                                          src={product.image[0]}
                                          width="30px"
                                          alt=""
                                          className="mr-3"
                                        />
                                        {product.name} X {product.quantity}{" "}
                                        <span>€{discountedPrice}</span>
                                      </li>
                                    </div>
                                  );
                                })}
                              </ul>
                              {/* <p>
                                Prix HT
                                <span>{cartPriceHT.toFixed(2)} €</span>
                              </p>
                              <p>
                                TVA
                                <span>{cartPriceTVA.toFixed(2)} €</span>
                              </p> */}
                              <p>
                                Frais de livraison <span>4.50 €</span>
                              </p>
                              <h4>
                                Prix TTC
                                <span>{cartPriceTTC.toFixed(2)} €</span>
                              </h4>
                            </div>
                          </div>
                          {/* Payment Method */}
                          <div className="col-12">
                            <h4 className="checkout-title">
                              Choisir un Moyen de payment:
                            </h4>
                            <div className="checkout-payment-method">
                              <div className="single-method">
                                <input
                                  type="radio"
                                  id="payment_stripe"
                                  name="payment-method"
                                  value="cb"
                                  checked={paymentMethod === "cb"}
                                  onChange={(e) =>
                                    setPaymentMethod(e.target.value)
                                  }
                                />
                                <label htmlFor="payment_stripe">CB</label>
                              </div>
                              <div className="single-method">
                                <input
                                  type="radio"
                                  id="payment_paypal"
                                  name="payment-method"
                                  value="payPal"
                                  onChange={(e) =>
                                    setPaymentMethod(e.target.value)
                                  }
                                  checked={paymentMethod === "payPal"}
                                />
                                <label htmlFor="payment_paypal">Paypal</label>
                              </div>
                            </div>
                            <div className="single-method mt-5">
                              <input
                                type="checkbox"
                                id="accept_terms"
                                onChange={(e) => setAccept(true)}
                              />
                              <label htmlFor="accept_terms">
                                I’ve read and accept the terms &amp; conditions{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                            <Link
                              to="/checkout"
                              className="lezada-button lezada-button--medium space-mt--20"
                            >
                              <IoIosArrowBack />
                              Back to checkout
                            </Link>
                            <button
                              // disabled={cart.length === 0}
                              onClick={placeOrderHandler}
                              className="lezada-button lezada-button--medium space-mt--20 ml-3"
                            >
                              Payer
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <div className="item-empty-area text-center">
                  <div className="item-empty-area__icon space-mb--30">
                    <IoMdCash />
                  </div>
                  <div className="item-empty-area__text">
                    <p className="space-mb--30">Aucun produit</p>
                    <Link
                      //   href="/shop/left-sidebar"
                      to="/shop"
                      className="lezada-button lezada-button--medium"
                    >
                      Achéter
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </LayoutFive>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData,
  };
};

export default connect(mapStateToProps)(Order);
