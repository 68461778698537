import React, { useEffect, useState } from "react";
import { LayoutFive } from "../../components/Layout";
import { SectionTitleThree } from "../../components/SectionTitle";
import { CategoryGridTwo } from "../../components/Category";
import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner/Banner";
import { ContainHome } from "../home/home.style";
import { TestimonialOne } from "../../components/Testimonial";
import testimonialTwoData from "../../data/testimonials/testimonial-one.json";
import Axios from "axios";

const Gallery = () => {
  const { t } = useTranslation();
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_REST_API_URL}api/other/testimonial`
    ).then((response) => {
      setTestimonials(response.data);
    });
  }, []);

  return (
    <LayoutFive>
      <Banner
        title="Galerie"
        image="/images/banners/banner-shop.jpg"
        // link="Gallerie"
      />
      <div className="container">
        <div
          className="element-wrapper mt-5"
          // style={{ background: "url(/images/bg/dots2.png) 0 0 repeat" }}
        >
          {/* <SectionTitleThree
            title={t("gallery.title")}
            subtitle={t("gallery.subTitle")}
          /> */}
          {/* category grid */}
          <CategoryGridTwo spaceBottomClass="space-mb--r100" />
        </div>
        <TestimonialOne
          testimonialData={testimonials}
          backgroundImage="/images/backgrounds/testimonials-bg.png"
        />
      </div>
    </LayoutFive>
  );
};

export default Gallery;
