import React from "react";
import { useTranslation } from "react-i18next";

const Activities = () => {
  const { t } = useTranslation();
  return (
    <div>
      <section className="section bg-dots">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="section-info">
                <div className="title-hr wow fadeInLeft"></div>
                <div
                  className=""
                  style={{
                    fontFamily: "proxima",
                    color: "black",
                  }}
                >
                  {t("activity.title")}
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row-services row">
                <div className="col-service col-sm-6 col-lg-4 wow fadeInUp">
                  <span className="text-dark">
                    {" "}
                    <img src="/images/moodboard.png" width="100px" alt="" />
                  </span>
                  <h4
                    style={{
                      fontFamily: "theano",
                    }}
                  >
                    {t("activity.act1")}
                  </h4>
                  <p
                    style={{
                      fontFamily: "proxima",
                      lineHeight: "30px",
                    }}
                  >
                    {t("activity.desc1")}
                  </p>
                </div>
                <div
                  className="col-service col-sm-6 col-lg-4 wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <span className="text-dark">
                    <img src="/images/design.png" width="100px" alt="" />
                  </span>
                  <h4
                    style={{
                      fontFamily: "theano",
                    }}
                  >
                    {t("activity.act2")}
                  </h4>
                  <p
                    style={{
                      fontFamily: "proxima",
                      lineHeight: "30px",
                    }}
                  >
                    {t("activity.desc2")}
                  </p>
                </div>
                <div className="clearfix visible-sm visible-md"></div>
                <div
                  className="col-service col-sm-6 col-lg-4 wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <span className="text-dark">
                    <img src="/images/plan.png" width="100px" alt="" />
                  </span>
                  <h4
                    style={{
                      fontFamily: "theano",
                    }}
                  >
                    {t("activity.act3")}
                  </h4>
                  <p
                    style={{
                      fontFamily: "proxima",
                      lineHeight: "30px",
                    }}
                  >
                    {t("activity.desc3")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Activities;
