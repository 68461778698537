import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { LayoutFive } from "../../components/Layout";
import { BreadcrumbOne } from "../../components/Breadcrumb";
import SignIn from "../../components/sign-in/sign-in.component";
import SignUp from "../../components/sign-up/sign-up.component";
import Banner from "../../components/Banner/Banner";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/actions/userActions";
import Message from "../../components/Message/Message";
import Loader from "../../components/Loader/Loader";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import Axios from "axios";
import { notification } from "antd";

const ResetEmail = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const history = useHistory();

  useEffect(() => {}, [history]);

  const openNotificationWithIcon = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    Axios.post(`${process.env.REACT_APP_REST_API_URL}api/auth/reset-email`, {
      email,
    })
      .then((res) => {
        // console.log("RES ==>", res);
        if (res) {
          openNotificationWithIcon(
            "success",
            "changer mot de passe",
            "Vous avez recu un mail pour le changement de votre mot de passe."
          );
          setError("");
          setEmail("");
        }
      })
      .catch((err) => {
        setError(err.response.data.message);
        // console.log("ERROOR =>", err.response.data.message);
      });
  };

  return (
    <LayoutFive>
      {/* breadcrumb */}
      <Banner
        title="Mot de passe oublié"
        image="/images/banners/banner.jpg"
        link="connexion"
      />
      <div className="login-area space-mt--r130 space-mb--r130">
        <Container>
          <Row>
            <Col lg={6} className="space-mb-mobile-only--50 mx-auto d-block">
              <div className="lezada-form login-form ">
                {error && <Message variant="danger">{error}</Message>}
                {/* {loading && <Loader />} */}
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <div className="section-title--login space-mb--50">
                        <h2 className="space-mb--20">Mot de passe oublié</h2>
                        <p>Entrer votre e-mail</p>
                      </div>
                    </Col>
                    <Col lg={12} className="space-mb--60">
                      <input
                        type="email"
                        placeholder="Votre E-mail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </Col>

                    <Col lg={12} className="space-mb--30">
                      <button
                        type="submit"
                        className="lezada-button lezada-button--medium"
                      >
                        Envoyer
                      </button>
                    </Col>
                    <Col>
                      <Link to={"/login-register"} className="reset-pass-link">
                        Retour
                      </Link>
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </LayoutFive>
  );
};

export default ResetEmail;
