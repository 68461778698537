import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import {
  Steps,
  Button,
  message,
  PageHeader,
  Form,
  Input,
  Radio,
  Select,
} from "antd";
import "antd/dist/antd.css";
import { Contain } from "./question.style";

const { Step } = Steps;
const { Option } = Select;

const Question = () => {
  const [current, setCurrent] = React.useState(0);
  const [check, setCheck] = React.useState(false);
  const [form] = Form.useForm();

  const onFinish = async (data) => {
    const datad = form.getFieldsValue();

    if (!check) {
      message.error("You have to accept conditions...");
      return;
    }

    const response = await axios.post(
      `${process.env.REACT_APP_REST_API_URL}api/other/question`,
      data
    );

    if (response) {
      message.success(response.data.message);
    }
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const Step1Form = () => {
    return (
      <>
        <div className="step">
          <h2 className="section_title">Presentation</h2>
          <h3 className="main_question">Personal info</h3>
          <div className="form-group add_top_30">
            <label>First and Last Name</label>
            <Form.Item name="name">
              <Input id="name" className="form-control" />
            </Form.Item>
          </div>
          <div className="form-group">
            <label>Email Address</label>
            <Form.Item
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
              name="email"
            >
              <Input id="email" className="form-control" />
            </Form.Item>
          </div>
          <div className="form-group">
            <label>Phone</label>
            <Form.Item
              rules={[
                {
                  required: true,
                },
              ]}
              name="phone"
            >
              <Input id="phone" className="form-control" />
            </Form.Item>
          </div>
          <label>Gender</label>
          <Form.Item name="size">
            <Radio.Group>
              <Radio.Button value="small">Small</Radio.Button>
              <Radio.Button value="default">Default</Radio.Button>
            </Radio.Group>
          </Form.Item>
          {/* <div className="form-group add_bottom_30 add_top_20">
            <label>
              Upload Resume
              <br />
              <small>
                (Files accepted: .pdf, .doc/docx - Max file size: 150KB for demo
                limit)
              </small>
            </label>
            <div className="fileupload">
              <Input
                type="file"
                name="fileupload"
                // accept=".pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                className="required"
                ref={register}
              />
            </div>
          </div> */}
        </div>
      </>
    );
  };

  const Step2Form = () => {
    return (
      <>
        <div className="step" data-state="branchtype">
          <h2 className="section_title">Work Availability</h2>
          <h3 className="main_question">Are you available for work?</h3>
          <Form.Item name="availability">
            <Radio.Group>
              <div className="form-group">
                <label className="container_radio version_2">
                  <Radio
                    // type="radio"
                    // name="availability"
                    value="Full-time"
                    className="required"
                  />
                  Full time availability
                  {/* <span className="checkmark"></span> */}
                </label>
                <label className="container_radio version_2">
                  <Radio
                    // type="radio"
                    // name="availability"
                    value="Part-time"
                    className="required"
                  />
                  Part time availability
                  {/* <span className="checkmark"></span> */}
                </label>
                <label className="container_radio version_2">
                  <Radio
                    // type="radio"
                    // name="availability"
                    value="Freelance-Contract"
                    className="required"
                  />
                  Freelance / Contract availability
                  {/* <span className="checkmark"></span> */}
                </label>
              </div>

              <small>* Start branch radio based </small>
            </Radio.Group>
          </Form.Item>
        </div>
      </>
    );
  };

  const Step3Form = () => {
    return (
      <>
        <div className="step" data-state="end">
          <h2 className="section_title">Work Availability</h2>
          <h3 className="main_question">
            Additional info about "Full Time" availability
          </h3>
          <div className="form-group add_bottom_30">
            <label>Minimum salary? (in USD)</label>
            <label>Choose a range</label>
            {/* <div className="styled-select"> */}
            <Form.Item
              name="minimum_salary_full_time"
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: 300 }}
                // className="form-control required"
                id="minimum_salary_full_time"
              >
                <Option value="">Choose a range</Option>
                <Option value="&lt;10k">&lt;10k</Option>
                <Option value="10-15k">10-15k</Option>
                <Option value="15-20k">15-20k</Option>
                <Option value="20-25k">20-25k</Option>
                <Option value="25-30k">25-30k</Option>
                <Option value="30-35k">30-35k</Option>
                <Option value="35-40k">35-40k</Option>
                <Option value="45-50k">45-50k</Option>
                <Option value="&gt;50k">&gt;50k</Option>
              </Select>
            </Form.Item>
            {/* </div> */}
          </div>
          <div className="form-group add_bottom_30">
            <label>How soon would you be looking to start?</label>
            <label>Choose your availability</label>
            {/* <div className="styled-select"> */}
            <Form.Item
              name="start_availability_full_time"
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: 300 }}
                // className="form-control required"
                id="start_availability_full_time"
              >
                <Option value="">Choose your availability</Option>
                <Option value="I can start immediately">
                  I can start immediately
                </Option>
                <Option value="I need to give 2–4 weeks notice<">
                  I need to give 2–4 weeks notice
                </Option>
                <Option value="I am passively browsing">
                  I am passively browsing
                </Option>
                <Option value="I will be available in a couple months">
                  I will be available in a couple months
                </Option>
                <Option value="I am not sure">I am not sure</Option>
              </Select>
            </Form.Item>
            {/* </div> */}
          </div>
          <label className="custom">Are you willing to work remotely?</label>
          <div className="form-group radio_input">
            <Form.Item name="remotely_full_time">
              <Radio.Group>
                <label className="container_radio mr-3">
                  Yes
                  <Radio
                    // type="radio"
                    // name="remotely_full_time"
                    value="Yes"
                    className="required"
                  />
                  {/* <span className="checkmark"></span> */}
                </label>
                <label className="container_radio">
                  No
                  <Radio
                    // type="radio"
                    // name="remotely_full_time"
                    value="No"
                    className="required"
                  />
                  {/* <span className="checkmark"></span> */}
                </label>
              </Radio.Group>
            </Form.Item>
          </div>
        </div>
      </>
    );
  };

  const StepEnd = () => {
    return (
      <>
        <div className="submit step" id="end">
          <div className="summary">
            <div className="wrapper">
              <h3>
                Thank your for your time
                <br />
                <span id="name_field"></span>!
              </h3>
              <p>
                We will contat you shorly at the following email address{" "}
                <strong id="email_field"></strong>
              </p>
            </div>
            <div className="text-center">
              <div className="form-group terms">
                <label className="container_check">
                  Please accept our{" "}
                  <a href="#" data-toggle="modal" data-target="#terms-txt">
                    Terms and conditions
                  </a>{" "}
                  before Submit
                  <input
                    type="checkbox"
                    name="terms"
                    value="Yes"
                    onChange={() => {
                      setCheck(true);
                    }}
                    className="required"
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const steps = [
    {
      step: 1,
      title: "First",
      content: <Step1Form />,
    },
    {
      step: 2,
      title: "Second",
      content: <Step2Form />,
    },
    { step: 3, title: "third", content: <Step3Form /> },
    { step: 4, title: "End", content: <StepEnd /> },
  ];

  return (
    <Contain>
      <div className="container-fluid">
        <div className="row row-height">
          <div className="col-xl-4 col-lg-4 content-left">
            <div className="content-left-wrapper">
              {/* <a href="index.html" id="logo">
                <img
                  src="/images/Logo-old-2.png"
                  alt=""
                  width="45"
                  height="45"
                />
              </a> */}
              <div id="social">
                <ul>
                  <li>
                    <a href="#0">
                      <i className="icon-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i className="icon-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i className="icon-google"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i className="icon-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <figure>
                  <img
                    src="images/form-wizard.png"
                    alt=""
                    className="img-fluid"
                    width="270"
                    height="270"
                  />
                </figure>
                <h2>We are Hiring</h2>
                <p>
                  Tation argumentum et usu, dicit viderer evertitur te has. Eu
                  dictas concludaturque usu, facete detracto patrioque an per,
                  lucilius pertinacia eu vel.
                </p>
                <Link to="/" className="btn_1 rounded yellow">
                  Go to home
                </Link>
                <a href="#start" className="btn_1 rounded mobile_btn yellow">
                  Start Now!
                </a>
              </div>
              {/* <div className="copy">© 2020 Potenza</div> */}
            </div>
          </div>

          <div className="col-xl-8 col-lg-8 content-right" id="start">
            <Form form={form} id="wrapped" onFinish={onFinish}>
              <div id="wizard_container">
                <Steps current={current}>
                  {steps.map((item) => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>
                {steps.map((item) => (
                  <div
                    key={item.step}
                    className={`steps-content mt-5 ${
                      item.step !== current + 1 && "hidden"
                    }`}
                  >
                    {item.content}
                  </div>
                ))}

                <div id="bottom-wizard">
                  {current < steps.length - 1 && (
                    <Button
                      type="button"
                      onClick={() => next()}
                      className="forward"
                    >
                      Next
                    </Button>
                  )}
                  {current === steps.length - 1 && (
                    <Button
                      className="submit"
                      htmlType="submit"
                      // onClick={() => message.success("Processing complete!")}
                    >
                      Done
                    </Button>
                  )}
                  {current > 0 && (
                    <Button
                      style={{ margin: "0 8px" }}
                      onClick={() => prev()}
                      className="backward"
                    >
                      Previous
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Contain>
  );
};
export default Question;
