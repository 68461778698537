import React, { Fragment, useEffect, useState } from "react";
import { Route, Router, Switch, withRouter } from "react-router-dom";
import HomePage from "./pages/home/home";
import ServicePage from "./pages/services/service";
import QuestionPage from "./pages/questions/question";
import SignInAndSignUpPage from "./pages/sign-in-and-sign-out/sign-in-and-sign-out.component.jsx";
import ContactPage from "./pages/contact/contact";
import ResetEmailPage from "./pages/reset-password/email";
import ResetPasswordPage from "./pages/reset-password/reset";
import ShopPage from "./pages/shop/shop";
import ProjectPage from "./pages/projects/project";
import ProjectDetailPage from "./pages/projects/project-detail/single";
import GalleryPage from "./pages/gallery/gallery";
import CartPage from "./pages/cart/cart";
import CheckoutPage from "./pages/ckeckout/checkout";
import ConditionPage from "./pages/condition/condition";
import ProductDetail from "./pages/shop/product-detail/product.jsx";
import BookPage from "./pages/book/book";
import WishlistPage from "./pages/wishlist/wishlist";
import Dashboard from "./pages/dashboard/dashboard";
import Order from "./pages/order/order";
import OrderDetail from "./pages/order/order-detail/detail";
import NotFound from "./pages/404/404";
import Log from "./assets/plantf.gif";

const Routes = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // setTimeout(() => {
    //   var element = document.getElementById("loading-screen")
    //   element.classList.remove("bounce-7")
    //   element.classList.add("move")
    // }, 4000)
    // setTimeout(() => setLoading(false), 5000)
  }, []);

  return (
    <div>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/service" component={ServicePage} />
        <Route path="/question" component={QuestionPage} />
        <Route path="/shop" component={ShopPage} />
        <Route path="/project" component={ProjectPage} />
        <Route path="/project-detail/:id" component={ProjectDetailPage} />
        <Route path="/search/:keyword" component={ShopPage} exact />
        <Route path="/page/:pageNumber" component={ShopPage} exact />
        <Route
          path="/search/:keyword/page/:pageNumber"
          component={ShopPage}
          exact
        />
        <Route path="/gallery" component={GalleryPage} />
        <Route path="/book" component={BookPage} />
        <Route path="/condition" component={ConditionPage} />
        <Route path="/login-register" component={SignInAndSignUpPage} />
        <Route path="/reset-password" component={ResetPasswordPage} />
        <Route path="/reset-email" component={ResetEmailPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/cart" component={CartPage} />
        <Route path="/checkout" component={CheckoutPage} />
        <Route path="/wishlist" component={WishlistPage} />
        <Route path="/account" component={Dashboard} />
        <Route path="/summary" component={Order} />
        <Route path="/order/:id" component={OrderDetail} />
        <Route path="/product-detail/:slug" component={ProductDetail} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default Routes;
