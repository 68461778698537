import React, { useEffect, useState } from "react";
import { LayoutFive } from "../../components/Layout";
import { HeroSliderSeven } from "../../components/HeroSlider";
import Activities from "../../components/Sections/Activities";
import Process from "../../components/Sections/Process";
import Projects from "../../components/Sections/Projects";
import About from "../../components/Sections/About";
import heroSliderData from "../../data/hero-sliders/hero-slider-two.json";
import projectData from "../../data/carousel/carousel.json";
import { ContainHome } from "./home.style";
import { ModalContainer } from "./modal.style";
// import Modal from "react-modal";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { IoIosMail, IoMdClose } from "react-icons/io";
import { SubscribeEmail, SubscribeEmailTwo } from "../../components/Newsletter";

const Home = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setDone(true);
  };

  const handleShow = () => setShow(true);
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);
  const [done, setDone] = useState(false);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);

    if (window.scrollY > 2000) {
      setShow(true);
    }
  };

  return (
    <LayoutFive>
      <ContainHome>
        <HeroSliderSeven
          sliderData={heroSliderData}
          spaceBottomClass="space-mb--50"
        />
        <div className="wrapper boxer">
          <div className="content">
            <About />
            <Activities />
            <Process />
            {/* <Projects /> */}
          </div>
        </div>
        {!done ? (
          <Modal show={show} onHide={handleClose}>
            <div className="mailchimp-wrapper">
              <IoMdClose
                onClick={handleClose}
                size={40}
                color="white"
                style={{
                  position: "absolute",
                  zIndex: "1000",
                  top: "25px",
                  left: "20px",
                }}
              />
              <Container>
                <Row>
                  <Col
                    lg={12}
                    style={{
                      padding: "0px",
                    }}
                  >
                    <div
                      className="mc-newsletter-content-container text-center space-pt--15 space-pb--15 space-pl--15 space-pr--15"
                      style={{
                        backgroundColor: "rgb(212, 186, 132)",
                      }}
                    >
                      <div className="mc-newsletter-content space-pt--70 space-pb--70">
                        <div className="icon">
                          <IoIosMail />
                        </div>
                        <div className="title space-mb--30">
                          <h3 className="space-mb--20">Newsletter</h3>
                          <p className="p-5">
                            Recevez notre newsletter avec la découverte de
                            différents pays et accessoires déco.
                          </p>
                        </div>
                        <div
                          className="mc-newsletter-form"
                          style={{
                            padding: "20px",
                          }}
                        >
                          <SubscribeEmailTwo closeModal={handleClose} />
                        </div>
                        {/* mailchimp alerts end */}
                        {/* <div className="link space-mt--50">
                            <a href="https://www.facebook.com" target="_blank">
                              Visit us on Facebook
                            </a>
                          </div> */}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            {/* <div className="container"></div> */}
          </Modal>
        ) : (
          ""
        )}
      </ContainHome>
    </LayoutFive>
  );
};

export default Home;
