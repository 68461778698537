import React from "react";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Paginator from "react-hooks-paginator";
import ReactDOM from "react-dom";
import { SlideDown } from "react-slidedown";
import { LayoutFive } from "../../components/Layout";
import { BreadcrumbOne } from "../../components/Breadcrumb";
import { getSortedProducts } from "../../lib/product";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../redux/actions/productActions";
import Loader from "../../components/Loader/Loader.js";
import Message from "../../components/Message/Message.js";
import { connect } from "react-redux";
// import products from "../../data/product.json";
import {
  ShopHeader,
  ShopFilter,
  ShopSidebar,
  ShopProducts,
} from "../../components/Shop";
import Banner from "../../components/Banner/Banner";
import Paginate from "../../components/Pagination/Pagination";
import usePagination from "../../components/Pagination/Paginate";
import { Pagination } from "@material-ui/lab";
import { IoIosBrowsers, IoIosTrash } from "react-icons/io";

const Shop = ({ match }) => {
  const param = useParams();
  let search = window.location.search;
  const cat = new URLSearchParams(search).get("cat");

  const keyword = param.keyword;

  const dispatch = useDispatch();
  const result = useSelector((state) => state.productData);
  const {
    loading,
    products,
    error,
    page,
    pages,
    pageLimit,
    count,
    filterProducts,
  } = result;

  const [layout, setLayout] = useState("grid four-column");
  const [sortType, setSortType] = useState("category");
  const [sortValue, setSortValue] = useState(cat ? cat : "");
  const [filterSortType, setFilterSortType] = useState("");
  const [filterSortValue, setFilterSortValue] = useState("");
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(page);
  const [currentData, setCurrentData] = useState([]);
  const [sortedProducts, setSortedProducts] = useState([]);
  const [shopTopFilterStatus, setShopTopFilterStatus] = useState(false);
  const [pageCurrent, setPageCurrent] = useState(page);

  const pageNumber = match.params.pageNumber || 1;

  const getLayout = (layout) => {
    setLayout(layout);
  };

  const getSortParams = (sortType, sortValue) => {
    setSortType(sortType);
    setSortValue(sortValue);
  };

  const getFilterSortParams = (sortType, sortValue) => {
    setFilterSortType(sortType);
    setFilterSortValue(sortValue);
  };

  useEffect(() => {
    dispatch(getProducts(keyword, pageNumber, sortValue, sortType));

    if (products) {
      let sortedProducts = getSortedProducts(products, sortType, sortValue);

      const filterSortedProducts = getSortedProducts(
        sortedProducts,
        filterSortType,
        filterSortValue
      );

      sortedProducts = filterSortedProducts;
      setSortedProducts(sortedProducts);

      // setCurrentData(sortedProducts.slice(offset, offset + pageLimit));
    }
  }, [
    offset,
    sortType,
    sortValue,
    filterSortType,
    filterSortValue,
    dispatch,
    keyword,
    pageNumber,
  ]);

  if (products) {
    return (
      <LayoutFive>
        <Banner
          title="Ma boutique"
          image="/images/banners/banner-shop.jpg"
          link="shop"
        />
        <div className="shop-page-content">
          {/* shop page header */}
          <ShopHeader
            getLayout={getLayout}
            getFilterSortParams={getFilterSortParams}
            productCount={products.length}
            sortedProductCount={currentData.length}
            shopTopFilterStatus={shopTopFilterStatus}
            setShopTopFilterStatus={setShopTopFilterStatus}
          />

          {/* shop header filter */}
          {/* <SlideDown closed={shopTopFilterStatus ? false : true}>
            <ShopFilter products={products} getSortParams={getSortParams} />
          </SlideDown> */}

          {/* shop page body */}
          <div className="shop-page-content__body space-mt--r130 space-mb--r130">
            <Container>
              {loading ? (
                <Loader />
              ) : error ? (
                <Message variant="danger">{error}</Message>
              ) : (
                <Row>
                  <Col lg={9} className="order-1 order-lg-2">
                    {/* shop products */}
                    {products && products.length === 0 ? (
                      <Container
                        style={{ textAlign: "center", marginTop: "10px" }}
                      >
                        <IoIosBrowsers size={100} />
                        <h1> Aucun produit </h1>
                      </Container>
                    ) : (
                      <ShopProducts layout={layout} products={products} />
                    )}

                    {/* <ShopProducts layout={layout} products={_DATA.currentData()} />  */}
                    {/* shop product pagination */}
                    <div className="pro-pagination-style">
                      <Paginate
                        pages={pages}
                        page={page}
                        keyword={keyword ? keyword : ""}
                      />
                    </div>
                  </Col>
                  <Col
                    lg={3}
                    className="order-2 order-lg-1 space-mt-mobile-only--50"
                  >
                    {/* shop sidebar */}
                    <ShopSidebar
                      products={products}
                      getSortParams={getSortParams}
                    />
                  </Col>
                </Row>
              )}
            </Container>
          </div>
        </div>
      </LayoutFive>
    );
  } else {
    return <Loader />;
  }
};

export default Shop;
