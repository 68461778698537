import React, { Fragment, Component, Suspense } from "react"
import ReactDOM from "react-dom"
import "./index.css"
import Routes from "./routes"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { ToastProvider } from "react-toast-notifications"
import { PersistGate } from "redux-persist/integration/react"
import { store, persistor } from "./redux/store"
// import { I18nextProvider } from "react-i18next";
import "./i18n"

import "./assets/scss/styles.scss"
import Loader from "./components/Loader/Loader"

// console.log("window.location.search ===>", window.location.pathname)
// if (window.location.pathname === "/debug") {
//   console.log("PURGE")
//   persistor.purge()
// }

console.disableYellowBox = true

ReactDOM.render(
  <ToastProvider placement="bottom-left">
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          <Suspense fallback={<div>{/* <Loader /> */}</div>}>
            <Routes />
          </Suspense>
        </PersistGate>
      </BrowserRouter>
    </Provider>
  </ToastProvider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
