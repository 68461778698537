import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Tab, Nav, Form, Badge } from "react-bootstrap";
import { FaCloudDownloadAlt, FaRegEdit } from "react-icons/fa";
import { LayoutFive } from "../../components/Layout";
import { BreadcrumbOne } from "../../components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { listMyOrders } from "../../redux/actions/orderActions";
import { useHistory } from "react-router-dom";
import { Loader, Message } from "semantic-ui-react";
import {
  updateUserProfile,
  getUserDetails,
  login,
} from "../../redux/actions/userActions";
import { useToasts } from "react-toast-notifications";
import { USER_UPDATE_PROFILE_RESET } from "../../redux/constants/userConstant";
import Banner from "../../components/Banner/Banner";

const Dashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [lastname, setLastName] = useState("");
  const [firstname, setFirstName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [message, setMessage] = useState(null);

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const userDetails = useSelector((state) => state.userDetails);
  const order = useSelector((state) => state.orderListMy);
  const userLogin = useSelector((state) => state.userLogin);

  const { orders, loading, error } = order;
  const { userInfo } = userLogin;
  // const { success, userInfo: userData } = userUpdateProfile;
  const { loading: loadingDetail, error: errorDetail, user } = userDetails;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login-register");
    } else {
      // if (!user) {

      dispatch({ type: USER_UPDATE_PROFILE_RESET });
      dispatch(getUserDetails());
      dispatch(listMyOrders());

      setName(user.name ? user.name : userInfo.name);
      setEmail(user.email ? user.email : userInfo.email);
      setPhone(user.phone ? user.phone : userInfo.phone);
      setLastName(user.lastname ? user.lastname : userInfo.lastname);
      setFirstName(user.firstname ? user.firstname : userInfo.firstname);
      // } else {
      // dispatch(listMyOrders());

      // setName(userInfo.name);
      // setEmail(userInfo.email);
      // setPhone(userInfo.phone);
      // setLastName(userInfo.lastname);
      // setFirstName(userInfo.firstname);

      // console.log("KO");
      // }
    }
  }, [dispatch, history, userInfo]);

  const profileHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateUserProfile({
        id: user._id,
        name,
        email,
        phone,
        firstname,
        lastname,
      })
    );

    addToast("Saved", {
      appearance: "success",
      autoDismiss: true,
    });
    // history.push("/account");
  };

  const passwordHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      addToast("Password do not match", {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      dispatch(updateUserProfile({ password }));
      addToast("Password updated", {
        appearance: "success",
        autoDismiss: true,
      });

      setPassword("");
      setConfirmPassword("");
    }
  };

  return (
    <LayoutFive>
      {/* breadcrumb */}
      <Banner
        title="Mon compte"
        image="/images/banners/banner.jpg"
        // link="service"
      />

      <div className="my-account-area space-mt--r130 space-mb--r130">
        <Container>
          <Tab.Container defaultActiveKey="dashboard">
            <Nav
              variant="pills"
              className="my-account-area__navigation space-mb--r60"
            >
              <Nav.Item>
                <Nav.Link eventKey="dashboard">Dashboard</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="orders">Mes commandes</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                    <Nav.Link eventKey="download">Download</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="payment">Payment</Nav.Link>
                  </Nav.Item> */}
              {/* <Nav.Item>
                    <Nav.Link eventKey="address">Address</Nav.Link>
                  </Nav.Item> */}
              <Nav.Item>
                <Nav.Link eventKey="accountDetails">Mes informations</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="dashboard">
                <div className="my-account-area__content">
                  <h3>Dashboard</h3>
                  <div className="welcome">
                    <p>
                      Hello,{" "}
                      <strong>
                        {userInfo && userInfo.data ? userInfo.data.name : name},
                      </strong>
                      {/* <strong>John !</strong>{" "} */}
                      {/* <Link
                            href="/ot/login-register"
                            to={process.env.PUBLIC_URL + "/other/login-register"}
                            className="logout"
                          >
                            Logout
                          </Link> */}
                    </p>
                  </div>
                  <p className="mt-3">
                    Nombre total commandes :{" "}
                    <span>{orders && orders.length}</span>
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="orders">
                <div className="my-account-area__content">
                  <h3>Mes commandes</h3>
                  <div className="myaccount-table table-responsive text-center">
                    {loading ? (
                      <Loader />
                    ) : error ? (
                      <Message variant="danger">{error}</Message>
                    ) : (
                      <table className="table table-bordered">
                        <thead className="thead-light">
                          <tr>
                            <th>N° commande</th>
                            <th>Date</th>
                            <th>Status</th>

                            <th>Prix Total</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {orders &&
                            orders.map((order) => (
                              <tr key={order._id}>
                                <td>1</td>
                                <td>Aug 22, 2018</td>
                                <td>
                                  {order.isSend ? (
                                    <Badge variant="success">Envoyer</Badge>
                                  ) : order.isDelivered ? (
                                    <Badge variant="info">Livrée ...</Badge>
                                  ) : (
                                    <Badge variant="primary">Payer</Badge>
                                  )}
                                </td>
                                {/* <td>
                                  {order.isPaid ? (
                                    <span>paid</span>
                                  ) : (
                                    <span>pending</span>
                                  )}
                                </td> */}
                                <td>{order.totalPrice}€</td>
                                <td>
                                  <Link to={`/order/${order._id}`}>Voir</Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </Tab.Pane>
              {/* <Tab.Pane eventKey="download">
                    <div className="my-account-area__content">
                      <h3>Downloads</h3>
                      <div className="myaccount-table table-responsive text-center">
                        <table className="table table-bordered">
                          <thead className="thead-light">
                            <tr>
                              <th>Product</th>
                              <th>Date</th>
                              <th>Expire</th>
                              <th>Download</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Haven - Free Real Estate PSD Template</td>
                              <td>Aug 22, 2020</td>
                              <td>Yes</td>
                              <td>
                                <a href="#" className="check-btn sqr-btn ">
                                  <FaCloudDownloadAlt /> Download File
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>HasTech - Portfolio Business Template</td>
                              <td>Sep 12, 2020</td>
                              <td>Never</td>
                              <td>
                                <a href="#" className="check-btn sqr-btn ">
                                  <FaCloudDownloadAlt /> Download File
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Tab.Pane> */}
              {/* <Tab.Pane eventKey="payment">
                    <div className="my-account-area__content">
                      <h3>Payment Method</h3>
                      <p className="saved-message">
                        You Can't Saved Your Payment Method yet.
                      </p>
                    </div>
                  </Tab.Pane> */}
              {/* <Tab.Pane eventKey="address">
                    <div className="my-account-area__content">
                      <h3>Billing Address</h3>
                      <address>
                        <p>
                          <strong>John Doe</strong>
                        </p>
                        <p>
                          1355 Market St, Suite 900 <br />
                          San Francisco, CA 94103
                        </p>
                        <p>Mobile: (123) 456-7890</p>
                      </address>
                      <a href="#" className="check-btn sqr-btn ">
                        <FaRegEdit /> Edit Address
                      </a>
                    </div>
                  </Tab.Pane> */}
              <Tab.Pane eventKey="accountDetails">
                <div className="my-account-area__content">
                  <h3>Mes informations</h3>
                  <div className="account-details-form">
                    <Form onSubmit={profileHandler}>
                      <Row>
                        <Col lg={6}>
                          <div className="single-input-item">
                            <label htmlFor="first-name" className="required">
                              Nom
                            </label>
                            <input
                              type="text"
                              id="first-name"
                              defaultValue={firstname}
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="single-input-item">
                            <label htmlFor="last-name" className="required">
                              Prénom
                            </label>
                            <input
                              type="text"
                              id="last-name"
                              defaultValue={lastname}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="single-input-item">
                        <label htmlFor="display-name" className="required">
                          Pseudo
                        </label>
                        <input
                          type="text"
                          id="display-name"
                          defaultValue={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="single-input-item">
                        <label htmlFor="number" className="required">
                          N° Téléphone
                        </label>
                        <input
                          type="text"
                          id="phone"
                          className="required"
                          defaultValue={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="single-input-item">
                        <label htmlFor="email" className="required">
                          Adresse Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          defaultValue={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="single-input-item mb-5">
                        <button type="submit">Save details</button>
                      </div>
                    </Form>
                    <form onSubmit={passwordHandler}>
                      <fieldset>
                        <legend>Changement de mot de passe</legend>

                        <div className="row">
                          <div className="col-lg-6">
                            <div className="single-input-item">
                              <label htmlFor="new-pwd" className="required">
                                Nouveau mot de passe
                              </label>
                              <input
                                type="password"
                                // placeholder="Enter password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="single-input-item">
                              <label htmlFor="confirm-pwd" className="required">
                                Confirmé
                              </label>
                              <input
                                type="password"
                                // placeholder="Confirm password"
                                value={confirmPassword}
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <div className="single-input-item">
                        <button type="submit">Enregistrer</button>
                      </div>
                    </form>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Container>
      </div>
    </LayoutFive>
  );
};

export default Dashboard;
