import React, { PureComponent } from "react";
import { Link, useHistory } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import CustomScroll from "react-custom-scroll";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { getDiscountPrice } from "../../../lib/product";
import { deleteFromCart } from "../../../redux/actions/cartActions";

const CartOverlay = ({
  activeStatus,
  getActiveStatus,
  cartItems,
  deleteFromCart,
}) => {
  let cartPriceHT = 0;
  let cartPriceTVA = 0;
  let cartPriceTTC = 0;
  let totalFrais = 0;
  const { addToast } = useToasts();
  const history = useHistory();

  const checkoutHandler = () => {
    history.push("/login-register?redirect=checkout");
    // history.push("/checkout");
  };

  return (
    <div className={`cart-overlay ${activeStatus ? "active" : ""}`}>
      <div
        className="cart-overlay__close"
        onClick={() => {
          getActiveStatus(false);
          document.querySelector("body").classList.remove("overflow-hidden");
        }}
      />
      <div className="cart-overlay__content">
        {/*=======  close icon  =======*/}
        <button
          className="cart-overlay__close-icon"
          onClick={() => {
            getActiveStatus(false);
            document.querySelector("body").classList.remove("overflow-hidden");
          }}
        >
          <IoIosClose />
        </button>
        {/*=======  offcanvas cart content container  =======*/}
        <div className="cart-overlay__content-container">
          <h3 className="cart-title">MON PANIER</h3>
          {cartItems.length >= 1 ? (
            <div className="cart-product-wrapper">
              <div className="cart-product-container">
                <CustomScroll allowOuterScroll={true}>
                  {cartItems.map((product, i) => {
                    const discountedPrice = getDiscountPrice(
                      product.price,
                      product.discount
                    ).toFixed(2);

                    cartPriceHT += discountedPrice * product.quantity;
                    // totalFrais += product.frais;
                    // cartPriceTVA += discountedPrice * product.quantity * 0.2;
                    // cartPriceTTC = cartPriceTVA + cartPriceHT;
                    cartPriceTTC = cartPriceHT + 4.5;

                    return (
                      <div className="single-cart-product" key={i}>
                        <span className="cart-close-icon">
                          <button
                            onClick={() => deleteFromCart(product, addToast)}
                          >
                            <IoIosClose />
                          </button>
                        </span>
                        <div className="image">
                          <Link
                            href={`/shop/product-basic/[slug]?slug=${product.slug}`}
                            to={`${process.env.PUBLIC_URL}/shop/product-basic/${product.slug}`}
                          >
                            <img
                              src={product.image[0]}
                              className="img-fluid"
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="content">
                          <h5>
                            <Link
                              href={`/shop/product-basic/[slug]?slug=${product.slug}`}
                              to={`${process.env.PUBLIC_URL}/shop/product-basic/${product.slug}`}
                            >
                              {product.name}
                            </Link>
                          </h5>
                          {product.selectedProductColor &&
                          product.selectedProductSize ? (
                            <div className="cart-item-variation">
                              <span>Color: {product.selectedProductColor}</span>
                              <span>Size: {product.selectedProductSize}</span>
                            </div>
                          ) : (
                            ""
                          )}
                          <p>
                            <span className="cart-count">
                              {product.quantity} x{" "}
                            </span>{" "}
                            <span className="discounted-price">
                              {discountedPrice} €
                            </span>
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </CustomScroll>
              </div>
              {/*=======  subtotal calculation  =======*/}
              {/* <p className="cart-subtotal">
                <span className="subtotal-title">Prix HT:</span>
                <span className="subtotal-amount">
                  {cartPriceHT.toFixed(2)}€
                </span>
              </p>
              <p className="cart-subtotal">
                <span className="subtotal-title">TVA:</span>
                <span className="subtotal-amount">
                  {cartPriceTVA.toFixed(2)}€
                </span>
              </p> */}
              <p className="cart-subtotal">
                <span className="subtotal-title">FRAIS DE LIVRAISON:</span>
                <span className="subtotal-amount">4.50 €</span>
              </p>
              <p className="cart-subtotal">
                <span className="subtotal-title">TOTAL:</span>
                <span className="subtotal-amount">
                  {cartPriceTTC.toFixed(2)}€
                </span>
              </p>
              {/*=======  cart buttons  =======*/}
              <div className="cart-buttons">
                <Link to={"/cart"}>PANIER</Link>
                <Link to={"/login-register?redirect=checkout"}>PAYER</Link>
              </div>
              {/*=======  free shipping text  =======*/}
            </div>
          ) : (
            "Vide"
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteFromCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartOverlay);
