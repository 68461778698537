import React, { useEffect, useState } from "react";
import { LayoutFive } from "../../components/Layout";
import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner/Banner";
import Axios from "axios";
// import projects from "../../data/carousel/carousel.json";
import ProjectCard from "../../components/Sections/Project";
import { ContainHome } from "../home/home.style";
import { CardDeck, CardGroup, Col } from "react-bootstrap";
import { Fade } from "react-reveal";

const Project = () => {
  // const { t } = useTranslation();
  const [projects, setProjects] = useState();

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_REST_API_URL}api/other/project`).then(
      (response) => {
        setProjects(response.data);
      }
    );
  }, []);

  // console.log("PROJECT ==>", projects);

  return (
    <LayoutFive>
      <ContainHome>
        <Banner title="Mes projets" image="/images/banners/banner-shop.jpg" />
        <div className="container">
          <div className="app-wrapper a-page">
            <div
              className=" o-container"
              style={{
                marginBottom: "7.6em",
                marginTop: "7.6em",
              }}
            >
              <div className="o-layout u-ml-2__360">
                <Fade top>
                  <div
                    className="o-layout-item u-1/2@from-small mt-5 col-md-6"
                    data-scroll
                  >
                    <h2
                      className=""
                      style={{
                        fontSize: "60px",
                        fontFamily: "theano",
                      }}
                    >
                      Découvrez mes projets
                    </h2>
                  </div>
                </Fade>
                <div
                  className="o-layout-item u-1/2@from-small col-md-6"
                  data-scroll
                >
                  <p
                    className="-line-height u-mb-5__360"
                    style={{
                      fontFamily: "proxima",
                    }}
                  >
                    {/* Voici une liste des projets sur lesquels j'ai travaillé */}
                  </p>
                </div>
              </div>
            </div>
            <div className="container" data-scroll-section>
              <CardDeck className="mb-5">
                {projects &&
                  projects.map((project) => (
                    <ProjectCard key={project.id} {...project} />
                  ))}
              </CardDeck>
            </div>
          </div>
        </div>
      </ContainHome>
    </LayoutFive>
  );
};

export default Project;
