import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { LayoutFive } from "../../components/Layout";
import Banner from "../../components/Banner/Banner";
import { useTranslation } from "react-i18next";
import Message from "../../components/Message/Message";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import Axios from "axios";
import { notification } from "antd";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

const ResetPassword = () => {
  let search = window.location.search;
  const user = new URLSearchParams(search).get("user");
  const token = new URLSearchParams(search).get("token");
  const history = useHistory();
  const { t } = useTranslation();

  const [hide, setHide] = useState(true);
  const [error, setError] = useState("");

  const { register, handleSubmit, errors, reset, watch } = useForm({
    validateCriteriaMode: "all",
    mode: "onChange",
  });

  const password = useRef({});
  password.current = watch("password", "");

  const openNotificationWithIcon = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  useEffect(() => {}, []);

  const onSubmit = async (data) => {
    // if (password !== newPassword) {
    //   openNotificationWithIcon(
    //     "error",
    //     "changer mot de passe",
    //     "Mot de passe sont differant"
    //   );
    // }
    // console.log(data);

    Axios.post(`${process.env.REACT_APP_REST_API_URL}api/auth/reset-password`, {
      password: data.password,
      userId: user,
      token: token,
    })
      .then((res) => {
        // console.log("RES ==>", res);
        if (res) {
          // console.log("res ===>", res);
          openNotificationWithIcon(
            "success",
            "changement mot de passe",
            "Changement effectué"
          );
          reset();
          setTimeout(() => {
            history.push("/login-register");
          }, 2000);
        }
      })
      .catch((err) => {
        setError(err.response.data.message);
        console.log("ERROOR =>", err.response.data.message);
      });
  };

  const showPassword = () => {
    setHide(hide ? false : true);
  };

  return (
    <LayoutFive>
      {/* breadcrumb */}
      <Banner
        title="Changement de mot de passe"
        image="/images/banners/banner.jpg"
      />
      <div className="login-area space-mt--r130 space-mb--r130">
        <Container>
          <Row>
            <Col lg={6} className="space-mb-mobile-only--50 mx-auto d-block">
              <div className="lezada-form login-form">
                {error && <Message variant="danger">{error}</Message>}

                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col lg={12}>
                      <div className="section-title--login space-mb--50">
                        <h2 className="space-mb--20">Nouveau de passe</h2>
                        <p></p>
                      </div>
                    </Col>
                    <Col lg={12} className="space-mb--60">
                      {hide ? (
                        <IoIosEye size={20} onClick={showPassword} />
                      ) : (
                        <IoIosEyeOff size={20} onClick={showPassword} />
                      )}
                      <input
                        id="password"
                        name="password"
                        placeholder="Mot de passe"
                        ref={register({
                          required: true,
                          minLength: 5,
                          validate: {
                            checkNumber: (value) => /\d+/g.test(value),
                            checkUppercase: (value) => /[A-Z]/g.test(value),
                          },
                        })}
                        type={hide ? "password" : "text"}
                      />
                      {errors?.password?.type === "required" && (
                        <p role="alert" className="mt-2 test-field">
                          Obligatoire
                        </p>
                      )}
                      {errors?.password?.type === "minLength" && (
                        <p role="alert" className="mt-2 test-field">
                          pas moins de 5 lettres
                        </p>
                      )}
                      {errors?.password?.type === "checkNumber" && (
                        <p role="alert" className="mt-2 test-field">
                          Au moins un chiffre
                        </p>
                      )}
                      {errors?.password?.type === "checkUppercase" && (
                        <p role="alert" className="mt-2 test-field">
                          Au moins une majuscule
                        </p>
                      )}
                    </Col>
                    <Col lg={12} className="space-mb--60">
                      <input
                        type={hide ? "password" : "text"}
                        placeholder="Confirmation mot de passe"
                        name="password_repeat"
                        ref={register({
                          required: true,
                          validate: {
                            checkPass: (value) =>
                              value === password.current ||
                              "les mot de passe ne sont pas identiques.",
                          },
                        })}
                      />
                      {errors?.password_repeat?.type === "required" && (
                        <p role="alert" className="mt-2 test-field">
                          Obligatoire
                        </p>
                      )}
                      {errors.password_repeat?.type === "checkPass" && (
                        <p role="alert" className="mt-2 test-field">
                          {errors.password_repeat.message}
                        </p>
                      )}
                    </Col>
                    <Col lg={12} className="space-mb--30">
                      <button className="lezada-button lezada-button--medium">
                        Enrégistrer
                      </button>
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </LayoutFive>
  );
};

export default ResetPassword;
