import { GET_PRODUCTS_REQUEST, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_FAIL} from '../constants/productConstant';

const initState = [];

const productReducer = (state = initState, action) => {

  switch (action.type) {
    case GET_PRODUCTS_REQUEST:
      return {
        ...state,
        products: [],
        loading: true
      };
      
    case GET_PRODUCTS_SUCCESS:
      
      return {
        ...state,
        products: action.payload.products,
        loading: false,
        pages: action.payload.pages,
        page: action.payload.page,
        pageLimit: action.payload.pageSize,
        filterProducts: action.payload.filter,
        count: action.payload.count
      };
    
    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    
    default:
      return state;
  }
};

export default productReducer;
