import React, { PureComponent } from "react"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { IoIosArrowRoundForward } from "react-icons/io"
import { useForm } from "react-hook-form"
import axios from "axios"
import { message, notification } from "antd"

const SubscribeEmailTwo = ({ mailchimpUrl, closeModal }) => {
  const { register, handleSubmit, errors, reset } = useForm()

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: "New-letter",
      description: message
    })
  }

  const onSubmit = async (data, e) => {
    const response = await axios.post(
      `${process.env.REACT_APP_REST_API_URL}api/other/newletter`,
      data
    )

    if (response.data.status === "error") {
      openNotificationWithIcon("error", "Cet e-mail existe deja")
    }

    openNotificationWithIcon("success", "Merci de votre confiance !")
    e.target.reset()
    closeModal()
  }

  return (
    <div className="subscribe-form" style={{ color: "black" }}>
      <div className="mc-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            ref={register({ required: true })}
            // className="input-gray"
            type="email"
            name="email"
            placeholder="Votre adresse e-mail"
            style={{ color: "black" }}
          />
          {errors.email && <p>Obligatoire</p>}
          <button className="button" type="submit" style={{ color: "black" }}>
            <IoIosArrowRoundForward size={40} color="black" />
          </button>
        </form>
      </div>
    </div>
  )
}

export default SubscribeEmailTwo
