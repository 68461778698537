import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
// <!-- <div class="dot-wrap clearfix"><ul class="slick-dots" role="tablist" style=""><li class="slick-active" role="presentation"><div class="p-btn"><img src="/images/icon/icon-process-01.png"></div></li><li role="presentation"><div class="p-btn"><img src="/images/icon/icon-process-02.png"></div></li><li role="presentation"><div class="p-btn"><img src="/images/process/icon-process-03.png"></div></li><li role="presentation"><div class="p-btn"><img src="/images/icon/icon-process-04.png"></div></li></ul></div> -->
const Process = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.getElementById("text-desc1").innerHTML = t("process.desc1");
    document.getElementById("text-desc11").innerHTML = t("process.desc1");
    document.getElementById("text-desc2").innerHTML = t("process.desc2");
    document.getElementById("text-desc22").innerHTML = t("process.desc2");
    document.getElementById("text-desc3").innerHTML = t("process.desc3");
    document.getElementById("text-desc33").innerHTML = t("process.desc3");
    document.getElementById("text-desc4").innerHTML = t("process.desc4");
    document.getElementById("text-desc44").innerHTML = t("process.desc4");
  });

  const settings = {
    dots: true,
    // appendDots: ".dot-wrap",
    dotsClass: "dot-wrap",
    fade: true,
    //   infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    // thumb: true,
    className: "our-process3-wrap clearfix js-slick-wrapper d-none d-sm-block",
    appendDots: (dots) => {
      return (
        <div>
          <ul className="slick-dots">
            {dots.map((item, index) => {
              return (
                <li key={index}>
                  <div className="p-btn">
                    <img
                      src={`/images/process/icon-process-0${item.props.children.props.children}.png`}
                      alt="Process Image "
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      );
    },
  };

  return (
    <div>
      <section className="our-process3">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2
                className="title title-2 title--36"
                style={{
                  fontFamily: "theano",
                }}
              >
                {t("process.subtitle")}
                <span className="under-title-2"></span>
              </h2>
            </div>
          </div>

          <div className="row d-block d-sm-none">
            <div className="col-lg-3 col-md-6 col-12">
              <div
                className="our-process2__item wow zoomIn"
                style={{ visibility: "visible", animationName: "zoomIn" }}
              >
                <h4
                  style={{
                    fontFamily: "theano",
                  }}
                >
                  <span>01 - </span>
                  {t("process.title1")}
                </h4>
                <div
                  style={{
                    fontFamily: "proxima",
                    lineHeight: "20px",
                    fontSize: "17px",
                    color: "white",
                  }}
                  id="text-desc11"
                ></div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div
                className="our-process2__item wow zoomIn"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "zoomIn",
                }}
              >
                <h4
                  style={{
                    fontFamily: "theano",
                  }}
                >
                  <span>02 - </span>
                  {t("process.title2")}
                </h4>
                <div
                  id="text-desc22"
                  style={{
                    fontFamily: "proxima",
                    lineHeight: "20px",
                    fontSize: "17px",
                    color: "white",
                  }}
                ></div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div
                className="our-process2__item wow zoomIn"
                data-wow-delay=".4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "zoomIn",
                }}
              >
                <h4
                  style={{
                    fontFamily: "theano",
                  }}
                >
                  <span>03 - </span>
                  {t("process.title3")}
                </h4>
                <div
                  id="text-desc33"
                  style={{
                    fontFamily: "proxima",
                    lineHeight: "20px",
                    fontSize: "17px",
                    color: "white",
                  }}
                ></div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div
                className="our-process2__item wow zoomIn"
                data-wow-delay=".6s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.6s",
                  animationName: "zoomIn",
                }}
              >
                <h4
                  style={{
                    fontFamily: "theano",
                  }}
                >
                  <span>04 - </span>
                  {t("process.title4")}
                </h4>
                <div
                  id="text-desc44"
                  style={{
                    fontFamily: "proxima",
                    lineHeight: "20px",
                    fontSize: "17px",
                    color: "white",
                  }}
                ></div>
              </div>
            </div>
          </div>

          <Slider {...settings}>
            <div
              className="our-process3__item clearfix"
              data-thumb="/images/icon/icon-process-01.png"
            >
              <div className="our-process3__text">
                <p
                  className="num"
                  style={{
                    fontFamily: "theano",
                  }}
                >
                  01
                </p>
                <p
                  className="head"
                  style={{
                    fontFamily: "theano",
                  }}
                >
                  {t("process.title1")}
                </p>
                <div
                  id="text-desc1"
                  style={{
                    fontFamily: "proxima",
                    lineHeight: "20px",
                  }}
                ></div>
              </div>
              <div className="our-process3__big-img">
                <img src="/images/process/process.jpg" alt="Process Image 1" />
              </div>
            </div>

            <div
              className="our-process3__item clearfix"
              data-thumb="/images/icon/icon-process-02.png"
            >
              <div className="our-process3__text">
                <p
                  className="num"
                  style={{
                    fontFamily: "theano",
                  }}
                >
                  02
                </p>
                <p
                  className="head"
                  style={{
                    fontFamily: "theano",
                  }}
                >
                  {t("process.title2")}
                </p>
                <div
                  id="text-desc2"
                  style={{
                    fontFamily: "proxima",
                    lineHeight: "20px",
                  }}
                ></div>
              </div>
              <div className="our-process3__big-img">
                <img
                  src="/images/process/process.jpg"
                  // src="/images/process/process-01.jpg"
                  alt="Process Image 2"
                  // width="920px"
                  // height="926px"
                />
              </div>
            </div>

            <div
              className="our-process3__item clearfix"
              data-thumb="/images/process/icon-process-03.png"
            >
              <div className="our-process3__text">
                <p
                  className="num"
                  style={{
                    fontFamily: "theano",
                  }}
                >
                  03
                </p>
                <p
                  className="head"
                  style={{
                    fontFamily: "theano",
                  }}
                >
                  {t("process.title3")}
                </p>
                <div
                  id="text-desc3"
                  style={{
                    fontFamily: "proxima",
                    lineHeight: "20px",
                  }}
                ></div>
              </div>
              <div className="our-process3__big-img">
                <img src="/images/process/process.jpg" alt="Process Image 3" />
              </div>
            </div>

            <div
              className="our-process3__item clearfix"
              data-thumb="/images/icon/icon-process-04.png"
            >
              <div className="our-process3__text">
                <p
                  className="num"
                  style={{
                    fontFamily: "theano",
                  }}
                >
                  04
                </p>
                <p
                  className="head"
                  style={{
                    fontFamily: "theano",
                  }}
                >
                  {t("process.title4")}
                </p>
                <div
                  id="text-desc4"
                  style={{
                    fontFamily: "proxima",
                    lineHeight: "20px",
                  }}
                ></div>
              </div>
              <div className="our-process3__big-img">
                <img src="/images/process/process.jpg" alt="Process Image 4" />
              </div>
            </div>
          </Slider>

          <div className="dot-wrap clearfix"></div>
        </div>
      </section>
    </div>
  );
};

export default Process;
