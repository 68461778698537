import React, { useState, useEffect, Fragment } from "react";
import { Button, Container } from "react-bootstrap";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  IoIosSearch,
  IoMdPerson,
  IoIosHeartEmpty,
  IoIosCart,
  IoIosMenu,
  IoIosArrowDown,
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoYoutube,
} from "react-icons/io";
import Navigation from "./elements/Navigation";
import AboutOverlay from "./elements/AboutOverlay";
import SearchOverlay from "./elements/SearchOverlay";
import CartOverlay from "./elements/CartOverlay";
import WishlistOverlay from "./elements/WishlistOverlay";
import MobileMenu from "./elements/MobileMenu";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/userActions";
import Lang from "../Language/Lang";
import { useTranslation } from "react-i18next";
import { ReactComponent as ShoppingIcon } from "../../assets/shopping-bag.svg";
import { Alert } from "antd";

const HeaderFive = ({ aboutOverlay, cartItems, wishlistItems }) => {
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [offCanvasAboutActive, setOffCanvasAboutActive] = useState(false);
  const [offCanvasSearchActive, setOffCanvasSearchActive] = useState(false);
  const [offCanvasCartActive, setOffCanvasCartActive] = useState(false);
  const [offCanvasWishlistActive, setOffCanvasWishlistActive] = useState(false);
  const [offCanvasMobileMenuActive, setOffCanvasMobileMenuActive] =
    useState(false);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // console.log(userInfo, "userInfo ==== ")

  useEffect(() => {
    const header = document.querySelector("header");
    setHeaderTop(header.offsetTop);
    setHeaderHeight(header.offsetHeight);
    window.addEventListener("scroll", handleScroll);
    scroll > headerTop
      ? (document.body.style.paddingTop = `${headerHeight}px`)
      : (document.body.style.paddingTop = 0);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Fragment>
      <header
        className={`topbar-shadow ${scroll > headerTop ? "is-sticky" : ""}`}
        style={{
          boxShadow: "0px 5px 2px 1px rgba(0, 0, 0, .2)",
          zIndex: "10",
          fontFamily: "proxima",
        }}
      >
        <div className="header-top-area space-pt--10 space-pb--10 d-none d-lg-block">
          {/* <Alert
            message="Annonce importante"
            description="Mes prestations sont actuellement en cours de modification"
            type="success"
            showIcon
            closable
          /> */}
          <Container className="wide">
            <div className="header-top">
              <div className="header-top__left">
                {/* <div className="language-change change-dropdown">
                  <span>English</span> <IoIosArrowDown />
                  <ul>
                    <li>
                      <button>English</button>
                    </li>
                    <li>
                      <button>Deustch</button>
                    </li>
                  </ul>
                </div> */}
                {/* <span className="header-separator">|</span> */}
                {/* <div className="currency-change change-dropdown">
                  <span>USD</span> <IoIosArrowDown />
                  <ul>
                    <li>
                      <button>USD</button>
                    </li>
                    <li>
                      <button>EUR</button>
                    </li>
                  </ul>
                </div> */}
                {/* <span className="header-separator">|</span> */}
                {/* <div className="order-online-text">
                  Order Online Call
                  <span className="number">(0123) 456789</span>
                </div> */}
              </div>
              <div className="header-content__logo space-pr--15">
                <Fade top>
                  <Link to="/">
                    <img
                      // width="200px"
                      src="/images/Logo-new.png"
                      className="img-fluid"
                      alt=""
                      style={{ width: "300px" }}
                    />
                  </Link>
                </Fade>
              </div>

              <div className="header-top__right">
                {/* <div className="signup-link mt-2">
                  {userInfo ? (
                    ""
                  ) : (
                    <Link href="" to="/login-register">
                      {t("auth.login")}
                    </Link>
                  )}
                </div>
                <span className="header-separator mt-1">|</span>
                <div className="top-social-icons">
                  <ul>
                    
                    <li>
                      <a href="https://www.facebook.com" target="_blank">
                        <IoLogoFacebook />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com" target="_blank">
                        <IoLogoInstagram />
                      </a>
                    </li>
                    
                  </ul>
                  <Link
                    style={{
                      background: "#d4ba84",
                      color: "white",
                      padding: "5px",
                      borderRadius: "2px",
                      boxShadow: "2px 2px 2px rgba(0, 0, 0, .2)",
                    }}
                    to={"/question"}
                    className=""
                  >
                    Demander un devis
                  </Link>
                </div> */}
              </div>
            </div>
          </Container>
        </div>
        <div className="header-bottom-area">
          {/* <Alert
            className="d-none d-sm-block d-md-none d-block d-sm-none d-none d-md-block d-lg-none"
            message="Annonce importante"
            description="Mes prestations sont actuellement en cours de modification"
            type="success"
            showIcon
            closable
          /> */}
          <Container className="wide">
            <div className="header-content d-flex align-items-center justify-content-between position-relative space-py-mobile-only--30">
              {/* logo */}
              <div className="header-content__logo space-pr--15">
                <div className="d-none d-lg-block d-xl-none d-none d-xl-block language-change change-dropdown ">
                  {/* <Lang /> */}
                  {/* <span>English</span> <IoIosArrowDown />
                  <ul>
                    <li>
                      <button>English</button>
                    </li>
                    <li>
                      <button>Deustch</button>
                    </li>
                  </ul> */}
                </div>
                <div className="d-none d-sm-block d-md-none d-block d-sm-none d-none d-md-block d-lg-none">
                  <Fade top>
                    <Link to="/">
                      <img
                        // width="100px"
                        src="/images/Logo-new.png"
                        className="img-fluid"
                        alt=""
                        style={{ width: "100px" }}
                      />
                    </Link>
                  </Fade>
                </div>
              </div>
              {/* navigation */}
              <Navigation />

              {/* icons */}
              <div className="header-content__icons header-content__navigation space-pl--15">
                <ul className="d-none d-lg-block">
                  {/* <li>
                    <button
                      onClick={() => {
                        setOffCanvasSearchActive(true);
                        document
                          .querySelector("body")
                          .classList.add("overflow-hidden");
                      }}
                    >
                      <IoIosSearch />
                    </button>
                  </li> */}
                  <li>
                    {userInfo ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Link to="">
                          <IoMdPerson />
                        </Link>

                        <div>
                          <span className="ml-2 mr-2">
                            {userInfo && userInfo.data
                              ? userInfo.data.name
                              : userInfo.name}
                          </span>
                          <IoIosArrowDown />
                          <ul className="sub-menu sub-menu--mega sub-menu--mega--column-2">
                            <ul className="sub-menu--mega__list">
                              <li>
                                <Link
                                  // href="/other/my-account"
                                  to={"/account"}
                                >
                                  Mon compte
                                </Link>
                              </li>
                              <li onClick={handleLogout}>
                                <Link to="/">Déconnexion</Link>
                              </li>
                            </ul>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <Link to="/login-register">Connexion</Link>
                    )}
                  </li>
                  <li>
                    {userInfo ? (
                      <button
                        onClick={() => {
                          setOffCanvasWishlistActive(true);
                          document
                            .querySelector("body")
                            .classList.add("overflow-hidden");
                        }}
                      >
                        <IoIosHeartEmpty />
                        {wishlistItems.length >= 1 ? (
                          <span className="count">
                            {wishlistItems.length ? wishlistItems.length : ""}
                          </span>
                        ) : (
                          ""
                        )}
                      </button>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        setOffCanvasCartActive(true);
                        document
                          .querySelector("body")
                          .classList.add("overflow-hidden");
                      }}
                    >
                      <IoIosCart />
                      {cartItems.length >= 1 ? (
                        <span className="count">
                          {cartItems.length ? cartItems.length : ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </button>
                  </li>
                </ul>

                <ul className="d-block d-lg-none">
                  <li>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Link to="/">
                        <IoMdPerson />
                      </Link>
                      {userInfo ? (
                        <div>
                          <span className="ml-2 mr-2">
                            {userInfo && userInfo.data
                              ? userInfo.data.name
                              : userInfo.name}
                          </span>
                          <IoIosArrowDown />
                          <ul className="sub-menu sub-menu--mega sub-menu--mega--column-2">
                            <ul className="sub-menu--mega__list">
                              <li>
                                <Link to={"/account"}>Mon compte</Link>
                              </li>
                              <li onClick={handleLogout}>
                                <Link to="/">Déconnexion</Link>
                              </li>
                            </ul>
                          </ul>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>
                  </li>
                  <li>
                    {userInfo ? (
                      <Link
                        to="/wishlist"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <IoIosHeartEmpty />
                        {wishlistItems.length >= 1 ? (
                          <span className="count">
                            {wishlistItems.length ? wishlistItems.length : ""}
                          </span>
                        ) : (
                          ""
                        )}
                      </Link>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    <Link
                      to="/cart"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <IoIosCart />
                      {/* <ShoppingIcon className="shopping-icon" /> */}
                      {cartItems.length >= 1 ? (
                        <span className="count">
                          {cartItems.length ? cartItems.length : ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </Link>
                  </li>
                  <li>
                    <button onClick={() => setOffCanvasMobileMenuActive(true)}>
                      <IoIosMenu />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </Container>
        </div>
      </header>

      {/* about overlay */}
      {aboutOverlay === false ? (
        ""
      ) : (
        <AboutOverlay
          activeStatus={offCanvasAboutActive}
          getActiveStatus={setOffCanvasAboutActive}
        />
      )}
      {/* search overlay */}
      <SearchOverlay
        activeStatus={offCanvasSearchActive}
        getActiveStatus={setOffCanvasSearchActive}
      />

      {/* cart overlay */}
      <CartOverlay
        activeStatus={offCanvasCartActive}
        getActiveStatus={setOffCanvasCartActive}
      />

      {/* wishlist overlay */}
      <WishlistOverlay
        activeStatus={offCanvasWishlistActive}
        getActiveStatus={setOffCanvasWishlistActive}
      />
      {/* Mobile Menu */}
      <MobileMenu
        activeStatus={offCanvasMobileMenuActive}
        getActiveStatus={setOffCanvasMobileMenuActive}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData,
    wishlistItems: state.wishlistData,
  };
};

export default connect(mapStateToProps)(HeaderFive);
