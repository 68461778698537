import React, { PureComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const CategoryGridTwo = ({ spaceBottomClass }) => {
  const { t } = useTranslation();

  const [categories, setCategories] = useState();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_REST_API_URL}api/product/category`)
      .then((response) => {
        setCategories(response.data.data);
      });
  }, []);

  const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
  `;

  return (
    <div
      className={`product-category-container ${
        spaceBottomClass ? spaceBottomClass : ""
      }`}
    >
      {/* <Container className=""> */}
      <Row style={{ justifyContent: "center" }}>
        {categories &&
          categories.map((category) => (
            <Col
              xs={12}
              sm={6}
              md={3}
              key={category.id}
              // style={{
              //   maxWidth: "40%",
              // }}
            >
              <div className="single-category single-category--one">
                <div className="single-category__image single-category__image--one">
                  <img
                    src={category.icon[0].url}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="single-category__content single-category__content--one space-mt--25 space-mb--25">
                  <div
                    className="title"
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "800",
                      color: "black",
                    }}
                  >
                    <p style={{ fontFamily: "theano" }}>{category.name}</p>
                    <Link
                      style={{ fontFamily: "theano" }}
                      to={`/shop?cat=${category.slug}`}
                    >
                      + Boutique
                    </Link>
                  </div>
                  {/* <p classslug="product-count">999</p> */}
                </div>
                <Link
                  to={`/shop?cat=${category.slug}`}
                  className="banner-link"
                ></Link>
              </div>
            </Col>
          ))}
      </Row>
      {/* </Container> */}
    </div>
  );
};

export default CategoryGridTwo;
