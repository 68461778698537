import React, { useEffect, useState } from "react";
import Card from "react-credit-cards";
import axios from "axios";

import "react-credit-cards/es/styles-compiled.css";
import { message } from "antd";
import Loader from "../Loader/Loader";

const Sumup = ({ amount, onSuccess, checkoutId, current, paymentMethod }) => {
  const [done, setDone] = useState();
  const [loading, setLoad] = useState(true);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_REST_API_URL}api/payment/sumup`, {
        amount,
        current,
        paymentMethod,
      })
      .then((res) => {
        // console.log("RESPONSE ===>>>>", res.data.data);

        if (res.data.data) {
          setLoad(false);
          onSuccess(res.data.data);
          window.SumUpCard.mount({
            checkoutId: res.data.data.id,
            locale: "fr-FR",
            onResponse: function (type, body) {
              // console.log("Type ===>", type);
              // console.log("Body ===>", body);
              onSuccess(body);
            },
          });
        }
      })
      .catch((error) => {
        // console.log("ERRRRR", error);
      });
  }, []);

  return (
    <div key="Payment">
      {loading && <Loader />}
      <div id="sumup-card"></div>
    </div>
  );
};

export default Sumup;
