import React, { useState, useEffect } from "react";

import { Link, useHistory } from "react-router-dom";
import { Row, Col, ListGroup, Image, Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message/Message";
import Loader from "../../components/Loader/Loader";
import { LayoutFive } from "../../components/Layout";
import { IoIosArrowBack } from "react-icons/io";
import Banner from "../../components/Banner/Banner";

const OrderDetail = ({ match, history }) => {
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  useEffect(() => {}, []);

  return (
    <LayoutFive className="m-5">
      <Banner
        title="Conditions générales d'utilisations"
        image="/images/banners/banner.jpg"
      />
      <div className="checkout-area space-mt--r130 space-mb--r130">
        <Container>
          <>
            <button
              onClick={history.goBack}
              className="lezada-button lezada-button--medium space-mt--20"
            >
              <IoIosArrowBack />
              Retour
            </button>
            {/* <h1>Order N° {order.orderNo}</h1> */}
            <Row>
              <Col md={12}>
                <div className="site-page-header-ghost-wrapper mt-5">
                  <p>
                    Veuillez trouver ci-dessous nos conditions générales
                    relatives à l'utilisation du site internet lovely-house.fr
                    et à l'achat de produits sur ce site.
                  </p>
                  <p>
                    N'hésitez pas à nous faire part de toute question relative
                    aux présentes conditions au moyen du formulaire de contact
                    ci-dessous.
                    <a href="https://lovelyhouse.fr/contact">
                      https://lovelyhouse.fr/contact
                    </a>
                  </p>
                  <p>
                    L'utilisation par les clients du site internet est régie par
                    les présentes conditions générales ainsi que tout avis ou
                    toutes conditions figurant sur une quelconque autre partie
                    du site internet. Nous précisons à toutes fins utiles que
                    LOVELY HOUSE peut modifier ou retirer à tout moment toute
                    partie du site internet, sans que sa responsabilité ne
                    puisse être engagée du fait de ces modifications à l'égard
                    des clients. LOVELY HOUSE se réserve le droit de modifier
                    les présentes conditions générales à l'avenir sans être
                    tenue d'en aviser spécifiquement les clients. Le fait de
                    poursuivre l'utilisation du site internet ou de passer
                    commande ultérieurement à ces modifications vaudra
                    acceptation de ces modifications.
                  </p>{" "}
                  <p>
                    Le contrat ne prendra effet entre vous (en qualité de
                    client) et nous qu'une fois que la commande aura été traitée
                    et expédiée.
                  </p>{" "}
                  <p>
                    En passant une commande au travers du présent site internet,
                    le client garantit :
                  </p>{" "}
                  <p>
                    1. - Qu'il n'est pas mineur ou dans l'incapacité légale de
                    passer un contrat.
                  </p>{" "}
                  <p>
                    2. - Que les coordonnées divulguées lors de l'enregistrement
                    sont complètes et exactes.
                  </p>{" "}
                  <p>
                    3. - Qu'il n'utilise pas un faux nom ou celui d'une autre
                    personne physique ou morale sans autorisation.
                  </p>{" "}
                  <br />
                  <h5>1. Passer une commande</h5>
                  <br />
                  <p>
                    En passant une commande, vous acceptez les présentes
                    conditions générales et reconnaissez les avoir lu avant de
                    procéder à la commande.
                  </p>
                  <h5>2. Annuler une commande En tant que</h5>
                  <br />
                  <p>
                    client, vous pouvez annuler une commande sous 14 jours et
                    vous serez remboursé du montant de cette dernière sur le
                    compte avec lequel vous avez payé votre commande.
                  </p>{" "}
                  <h5>3. Accepter une commande</h5>
                  <br />
                  <p>
                    Lorsqu'une commande est passée, vous recevrez une
                    confirmation de commande à l'adresse e-mail fournie lors du
                    paiement, contenant les informations sur le contenu et le
                    prix de la commande.
                  </p>
                  <h5>4. Livraison</h5>
                  <br />
                  <p>
                    En tant qu'acheteur, vous pouvez choisir parmi les options
                    de livraison mentionnées et le moment qui vous convient le
                    mieux. LOVELY HOUSE ne peut être tenu responsable de tout
                    dommage causé pendant le transport ou dû à tout retard
                    indépendant de sa volonté.
                  </p>{" "}
                  <h5>5. Politique de retour</h5>
                  <br />
                  <p>
                    LOVELY HOUSE accepte les retours sous 14 jours si les
                    articles n'ont pas été utilisés, modifiés, lavés ou
                    autrement manipulés. Les articles doivent être retournés
                    dans leur emballage d'origine. Les articles ne peuvent être
                    retournés à LOVELY HOUSE sans le consentement écrit
                    préalable de LOVELY HOUSE et sont soumis à des frais de
                    retour.
                  </p>
                  <h5>6. Responsabilité</h5>
                  <br />
                  <p>
                    Nous nous efforçons de maintenir les informations figurant
                    sur le présent site internet aussi exactes que possible ;
                    toutefois, nous ne formulons aucune garantie à cet égard,
                    qu'elle soit expresse ou implicite. De même, nous ne
                    formulons aucune garantie à l'égard de l'utilisation du
                    présent site internet ; il incombe à chaque utilisateur de
                    s'assurer que ses appareils sont protégés contre les virus
                    ou contre tout autre facteur extérieur. Vos droits sont
                    protégés en vertu de la loi française sur la vente de
                    marchandises et la prestation de services, ainsi que de la
                    loi en matière de protection des consommateurs, en votre
                    qualité de consommateur. Le présent site internet
                    n'affectera pas vos droits en vertu du droit en vigueur de
                    quelque manière que ce soit.
                  </p>
                  <h5>7. Divisibilité</h5>
                  <br />
                  <p>
                    Toute disposition des présentes conditions générales qui
                    viendrait à être nulle, illégale ou non applicable pour
                    quelque raison que ce soit sera réputée dissociée du reste
                    des conditions générales qui demeureront valables et
                    exécutoires.
                  </p>
                </div>
              </Col>
            </Row>
          </>
        </Container>
      </div>
    </LayoutFive>
  );
};

export default OrderDetail;
