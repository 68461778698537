import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const MobileMenuNav = ({ getActiveStatus }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const offCanvasNav = document.querySelector(
      "#offcanvas-mobile-menu__navigation"
    );
    const offCanvasNavSubMenu =
      offCanvasNav.querySelectorAll(".mobile-sub-menu");
    const anchorLinks = offCanvasNav.querySelectorAll("a");

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML(
        "beforebegin",
        "<span class='menu-expand'><i></i></span>"
      );
    }

    const menuExpand = offCanvasNav.querySelectorAll(".menu-expand");
    const numMenuExpand = menuExpand.length;

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener("click", (e) => {
        sideMenuExpand(e);
      });
    }

    for (let i = 0; i < anchorLinks.length; i++) {
      anchorLinks[i].addEventListener("click", () => {
        getActiveStatus(false);
      });
    }
  });

  const sideMenuExpand = (e) => {
    e.currentTarget.parentElement.classList.toggle("active");
  };
  return (
    <nav
      className="offcanvas-mobile-menu__navigation"
      id="offcanvas-mobile-menu__navigation"
    >
      <ul>
        <li className="menu-item-has-children">
          <Link href="/" to="">
            {t("navigation.home")}
          </Link>
        </li>

        <li className="menu-item-has-children">
          <Link to="/gallery">{t("navigation.shop")}</Link>
        </li>

        <li className="menu-item-has-children">
          <Link to="/service">{t("navigation.service")}</Link>
        </li>

        <li className="menu-item-has-children">
          <Link to="/project">Mes projets</Link>
        </li>

        <li className="menu-item-has-children">
          <Link to="/book">Mon livre</Link>
        </li>

        <li className="menu-item-has-children">
          <Link to="/contact">{t("navigation.contact")}</Link>
        </li>
      </ul>
    </nav>
  );
};

export default MobileMenuNav;
