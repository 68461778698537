import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { login } from "../../redux/actions/userActions";
import Message from "../Message/Message";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { notification } from "antd";

const SignIn = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hide, setHide] = useState(true);
  const history = useHistory();

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error = "", userInfo } = userLogin;

  const openNotificationWithIcon = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  const redirect = window.location.search
    ? window.location.search.split("=")[1]
    : "/";

  useEffect(() => {
    // console.log("REDIRECT", redirect);
    if (userInfo) {
      history.push(redirect);
    }

    // if (redirect === "checkout") {
    //   openNotificationWithIcon(
    //     "error",
    //     "Achet",
    //     "Vous devez vous connectez avant de continuer"
    //   );
    // }
  }, [history, userInfo, redirect]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    dispatch(login(email, password));
  };

  const showPassword = () => {
    setHide(hide ? false : true);
  };

  return (
    <Col lg={6} className="space-mb-mobile-only--50">
      <div className="lezada-form login-form">
        {error && <Message variant="danger">{error}</Message>}
        {loading && <Loader />}
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={12}>
              <div className="section-title--login text-center space-mb--50">
                <h2 className="space-mb--20">{t("auth.loginTitle")}</h2>
                <p>{t("auth.loginSub")}</p>
              </div>
            </Col>
            <Col lg={12} className="space-mb--60">
              <input
                type="email"
                placeholder="Votre E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Col>
            <Col lg={12} className="space-mb--60">
              {hide ? (
                <IoIosEye size={20} onClick={showPassword} />
              ) : (
                <IoIosEyeOff size={20} onClick={showPassword} />
              )}
              <input
                type={hide ? "password" : "text"}
                placeholder="Votre mot de passe"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Col>
            <Col lg={12} className="space-mb--30">
              <button
                type="submit"
                className="lezada-button lezada-button--medium"
              >
                Connexion
              </button>
            </Col>
            <Col>
              {/* <input type="checkbox" />{" "}
              <span className="remember-text">Remember me</span> */}
              <Link to={"/reset-email"} className="reset-pass-link">
                Mot de passe oublié ?
              </Link>
            </Col>
          </Row>
        </form>
      </div>
    </Col>
  );
};

export default SignIn;
