import axios from "axios";
import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
} from "../constants/productConstant";

export const getProducts = (
  keyword = "",
  pageNumber = "",
  sortValue = "",
  sortType = ""
) => async (dispatch) => {
  try {
    dispatch({ type: GET_PRODUCTS_REQUEST });

    const final = {};

    const { data } = await axios.get(
      `${process.env.REACT_APP_REST_API_URL}api/product/all?keyword=${keyword}&pageNumber=${pageNumber}&sortValue=${sortValue}&sortType=${sortType}`
    );

    const response = data.findAllProductsData;
    const dataFilter = data.dataForFilter;

    for (let product of response) {
      if (product.variation.length === 0) {
        delete product["variation"];
      }
      // product.tag = product.tag.map((tag) => tag.name);
      product.image = product.image.map((image) => image.url);
      // product.category = product.category.map((category) => category.name);
    }

    for (let productFilter of dataFilter) {
      if (productFilter.variation.length === 0) {
        delete productFilter["variation"];
      }
      // productFilter.tag = productFilter.tag.map((tag) => tag.name);
      productFilter.image = productFilter.image.map((image) => image.url);
      // productFilter.category = productFilter.category.map(
      //   (category) => category.name
      // );
    }

    final.products = response;
    final.page = data.page;
    final.pages = data.pages;
    final.pageSize = data.pageSize;
    final.filter = dataFilter;
    final.count = data.count;

    dispatch({
      type: GET_PRODUCTS_SUCCESS,
      payload: final,
    });
  } catch (error) {
    dispatch({
      type: GET_PRODUCTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export function getProduct(id) {
  try {
    const request = axios
      .get(`${process.env.REACT_APP_REST_API_URL}api/product/single/${id}`)
      // .then(res => res.json())
      .then((response) => {
        response.data.data.tag = response.data.data.tag.map((tag) => tag.name);
        response.data.data.image = response.data.data.image.map(
          (image) => image.url
        );
        response.data.data.category = response.data.data.category.map(
          (category) => category.name
        );

        return response.data.data;
      });

    return {
      type: "GET_PRODUCT",
      payload: request,
    };
  } catch (error) {
    // console.log("ERROR IS", error);
  }
}
