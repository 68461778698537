import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { registerUser } from "../../redux/actions/userActions";
import Message from "../Message/Message";
import Loader from "../Loader/Loader";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";

import "./sign-up.styles.scss";

const SignUp = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();

  const { register, handleSubmit, errors, reset } = useForm({
    validateCriteriaMode: "all",
    mode: "onChange",
  });

  const openNotificationWithIcon = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  const [hide, setHide] = useState(true);

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userInfo } = userRegister;

  const redirect = window.location.search
    ? window.location.search.split("=")[1]
    : "/";

  useEffect(() => {
    // console.log("USERR ==>", userInfo, redirect, window.location);
    if (userInfo) {
      history.push(redirect);
    }
    if (
      redirect === "checkout" &&
      window.location.href === "http://localhost:3000/login-register"
    ) {
      openNotificationWithIcon(
        "error",
        "Achet",
        "Vous devez vous connectez avant de continuer"
      );
    }
  }, [history, userInfo, redirect]);

  const onSubmit = async (data) => {
    // console.log("DATA ERROR ==>", error);

    dispatch(registerUser(data.name, data.email, data.password));

    if (!error) {
      openNotificationWithIcon(
        "success",
        "Bienvenue",
        "Votre compte a bien été créé"
      );
    }
    reset();

    // history.push("/login-register");
  };

  const showPassword = () => {
    setHide(hide ? false : true);
  };

  // console.log("ERRO ==>", errors);
  return (
    <Col lg={6}>
      <div className="lezada-form login-form--register">
        {/* {error && <Message variant='danger'>{error}</Message>} */}
        {error && <Message variant="danger">{error}</Message>}
        {loading && <Loader />}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col lg={12}>
              <div className="section-title--login text-center space-mb--50">
                <h2 className="space-mb--20">{t("auth.registerTitle")}</h2>
                <span>{t("auth.registerSub")}</span>
              </div>
            </Col>
            <Col lg={12} className="space-mb--30">
              <label htmlFor="email">Pseudo</label>
              <input
                id="name"
                name="name"
                placeholder="Pseudo"
                ref={register({
                  required: "Obligatoire",
                  pattern: {
                    message: "Champ obligatoire",
                  },
                })}
                type="text"
              />
              {errors.name && (
                <p role="alert" className="mt-2 test-field">
                  {errors.name.message}
                </p>
              )}
            </Col>
            <Col lg={12} className="space-mb--30">
              <label htmlFor="email">e-mail</label>
              <input
                id="email"
                name="email"
                placeholder="E-mail"
                ref={register({
                  required: "Obligatoire",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "email non conforme",
                  },
                })}
                type="email"
              />
              {errors.email && (
                <p role="alert" className="mt-2 test-field">
                  {errors.email.message}
                </p>
              )}
            </Col>
            <Col lg={12} className="space-mb--50">
              <label htmlFor="password">Mot de passe</label>
              {hide ? (
                <IoIosEye size={20} onClick={showPassword} className="ml-3" />
              ) : (
                <IoIosEyeOff
                  size={20}
                  onClick={showPassword}
                  className="ml-3"
                />
              )}
              <input
                id="password"
                name="password"
                placeholder="Mot de passe"
                ref={register({
                  required: true,
                  minLength: 5,
                  validate: {
                    checkNumber: (value) => /\d+/g.test(value),
                    checkUppercase: (value) => /[A-Z]/g.test(value),
                  },
                })}
                type={hide ? "password" : "text"}
              />
              {errors?.password?.type === "required" && (
                <p role="alert" className="mt-2 test-field">
                  Obligatoire
                </p>
              )}
              {errors?.password?.type === "minLength" && (
                <p role="alert" className="mt-2 test-field">
                  pas moins de 5 lettres
                </p>
              )}
              {errors?.password?.type === "checkNumber" && (
                <p role="alert" className="mt-2 test-field">
                  Au moins un chiffre
                </p>
              )}
              {errors?.password?.type === "checkUppercase" && (
                <p role="alert" className="mt-2 test-field">
                  Au moins une majuscule
                </p>
              )}
            </Col>

            <Col lg={12} className="text-center">
              <button
                type="submit"
                // disabled={check}
                className="lezada-button lezada-button--medium"
              >
                Enrégistrer
              </button>
            </Col>
          </Row>
        </form>
      </div>
    </Col>
  );
};

export default SignUp;
