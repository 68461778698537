import React, { useState, useEffect } from "react";
import axios from "axios";
import PaypalButton from "../../../components/Payment/Paypal";
import StripeButton from "../../../components/Payment/Stripe";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, ListGroup, Image, Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../../components/Message/Message";
import Loader from "../../../components/Loader/Loader";
import { LayoutFive } from "../../../components/Layout";
import { deleteAllFromCart } from "../../../redux/actions/cartActions";
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
} from "../../../redux/actions/orderActions.js";
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from "../../../redux/constants/orderConstant";
import { BreadcrumbOne } from "../../../components/Breadcrumb";
import { IoIosArrowBack } from "react-icons/io";
import Banner from "../../../components/Banner/Banner";
import { useToasts } from "react-toast-notifications";
import { Descriptions, PageHeader, Statistic, Card } from "antd";
import daysjs from "dayjs";

const OrderDetail = ({ match, history }) => {
  const orderId = match.params.id;
  const dispatch = useDispatch();

  // const history = useHistory();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login-register");
    }

    dispatch(getOrderDetails(orderId));
  }, [dispatch, orderId]);

  return (
    <LayoutFive className="m-5">
      <Banner
        title={`Commande N° ${order && order.orderNo}`}
        image="/images/banners/banner.jpg"
      />
      <div className="checkout-area space-mt--r130 space-mb--r130">
        <Container>
          <>
            <button
              onClick={() => history.push("/account")}
              className="lezada-button lezada-button--medium space-mt--20"
            >
              <IoIosArrowBack />
              Mon compte
            </button>

            <Row>
              <Col md={12}>
                <h1 className="mt-5 text-center">
                  Récapitulatif de la commande
                </h1>
                <div className="site-page-header-ghost-wrapper mt-5">
                  <PageHeader ghost={false} title="Informations">
                    <Descriptions size="small" column={3}>
                      <Descriptions.Item label="Nom">
                        {userInfo.name}
                      </Descriptions.Item>

                      <Descriptions.Item label="E-mail">
                        {userInfo.email}
                      </Descriptions.Item>
                      <Descriptions.Item label="Adresse">
                        {order && order.shippingAddress.address},
                        {order && order.shippingAddress.city}
                        {order && order.shippingAddress.postalCode},
                        {order && order.shippingAddress.country}
                      </Descriptions.Item>
                    </Descriptions>
                  </PageHeader>
                  <PageHeader
                    ghost={false}
                    className="mt-3"
                    // onBack={() => window.history.back()}
                    title="Livraison"
                  >
                    <Descriptions size="small" column={3}>
                      <Descriptions.Item label="Type de paiement">
                        {order && order.paymentMethod}
                      </Descriptions.Item>
                      <Descriptions.Item label=""></Descriptions.Item>
                      <Descriptions.Item label=""></Descriptions.Item>
                      <Descriptions.Item label="Statut de la livraison"></Descriptions.Item>
                      <Descriptions.Item label=""></Descriptions.Item>
                    </Descriptions>
                    <Row gutter={16}>
                      <Col span={12}>
                        {order && order.isPaid ? (
                          <Card>
                            <Statistic
                              title="Payé le"
                              value={`${daysjs(order.paidAt).format(
                                "DD-MM-YYYY - HH:mm:ss"
                              )}`}
                              precision={2}
                              valueStyle={{ color: "#3f8600" }}
                              // prefix={<ArrowUpOutlined />}
                            />
                          </Card>
                        ) : (
                          <Message variant="danger">Pas payer</Message>
                        )}
                      </Col>
                      <Col span={12}>
                        {order && order.isDelivered ? (
                          <Card>
                            <Statistic
                              title="Livré le"
                              value={`${daysjs(order.deliveredAt).format(
                                "DD-MM-YYYY - HH:mm:ss"
                              )}`}
                              precision={2}
                              valueStyle={{ color: "#cf1322" }}
                              // prefix={<ArrowDownOutlined />}
                            />
                          </Card>
                        ) : (
                          <Message variant="danger">
                            En cours de livraison
                          </Message>
                        )}
                      </Col>
                      <Col span={12}>
                        {order && order.isSend ? (
                          <Card>
                            <Statistic
                              title="Envoyé le"
                              value={`${daysjs(order.sendAt).format(
                                "DD-MM-YYYY - HH:mm:ss"
                              )}`}
                              precision={2}
                              valueStyle={{ color: "#007bff" }}
                              // prefix={<ArrowDownOutlined />}
                            />
                          </Card>
                        ) : (
                          <Message variant="danger">
                            En cours de préparation
                          </Message>
                        )}
                      </Col>
                    </Row>
                  </PageHeader>
                  <PageHeader
                    ghost={false}
                    className="mt-3"
                    // onBack={() => window.history.back()}
                    title="Articles de ma commande"
                    subTitle={`Prix Total: ${order && order.totalPrice} €`}
                  >
                    <ListGroup variant="flush">
                      {order &&
                        order.orderItems.map((item, index) => (
                          <ListGroup.Item key={index}>
                            <Row>
                              <Col md={1}>
                                <img
                                  src={item.image[0]}
                                  width="20px"
                                  alt={item.name}
                                />
                              </Col>
                              <Col>
                                <Link to={`/product/${item.product}`}>
                                  {item.name}
                                </Link>
                              </Col>
                              <Col md={4}>
                                {item.quantity} x €{item.price} = €
                                {item.quantity * item.price}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  </PageHeader>
                </div>
                {/* <small></small> */}
              </Col>
            </Row>
          </>
        </Container>
      </div>
    </LayoutFive>
  );
};

export default OrderDetail;
