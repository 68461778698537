import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  addToWishlist,
  deleteFromWishlist,
  deleteAllFromWishlist,
} from "../../redux/actions/wishlistActions";
import { addToCart } from "../../redux/actions/cartActions";
import { getDiscountPrice } from "../../lib/product";
import { LayoutFive } from "../../components/Layout";
import { BreadcrumbOne } from "../../components/Breadcrumb";
import { IoIosClose, IoIosHeartEmpty } from "react-icons/io";
import Banner from "../../components/Banner/Banner";

const Wishlist = ({
  wishlistItems,
  cartItems,
  addToCart,
  deleteFromWishlist,
  deleteAllFromWishlist,
}) => {
  const { addToast } = useToasts();

  useEffect(() => {
    document.querySelector("body").classList.remove("overflow-hidden");
  });

  return (
    <LayoutFive>
      {/* breadcrumb */}
      <Banner
        title="Galerie"
        image="/images/banners/banner-shop.jpg"
        // link="Gallerie"
      />

      {/* wishlist content */}
      <div className="wishlist-content space-mt--r130 space-mb--r130">
        <Container>
          {wishlistItems && wishlistItems.length >= 1 ? (
            <Row>
              <Col lg={12}>
                {/* cart table */}
                <table className="cart-table">
                  <thead>
                    <tr>
                      <th className="product-name" colSpan="2">
                        Produit
                      </th>
                      <th className="product-price">Prix</th>
                      <th className="product-subtotal">&nbsp;</th>
                      <th className="product-remove">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {wishlistItems.map((product, i) => {
                      const discountedPrice = getDiscountPrice(
                        product.price,
                        product.discount
                      ).toFixed(2);

                      const cartItem = cartItems.filter(
                        (item) => item.id === product.id
                      )[0];

                      return (
                        <tr key={i}>
                          <td className="product-thumbnail">
                            <Link
                              href={`/shop/product-basic/[slug]?slug=${product.slug}`}
                              to={`${process.env.PUBLIC_URL}/shop/product-basic/${product.slug}`}
                            >
                              <img
                                src={product.image[0]}
                                className="img-fluid"
                                alt=""
                              />
                            </Link>
                          </td>
                          <td className="product-name">
                            <Link
                              href={`/shop/product-basic/[slug]?slug=${product.slug}`}
                              to={`${process.env.PUBLIC_URL}/shop/product-basic/${product.slug}`}
                            >
                              {product.name}
                            </Link>
                            {product.selectedProductColor &&
                            product.selectedProductSize ? (
                              <div className="product-variation">
                                <span>
                                  Color: {product.selectedProductColor}
                                </span>
                                <span>Size: {product.selectedProductSize}</span>
                              </div>
                            ) : (
                              ""
                            )}
                          </td>

                          <td className="product-price">
                            <span className="price">{product.price} €</span>
                          </td>

                          <td>
                            {product.affiliateLink ? (
                              <a
                                href={product.affiliateLink}
                                target="_blank"
                                className="lezada-button lezada-button--medium"
                              >
                                Buy now
                              </a>
                            ) : product.variation &&
                              product.variation.length >= 1 ? (
                              <Link
                                href={`/shop/product-basic/[slug]?slug=${product.slug}`}
                                to={`${process.env.PUBLIC_URL}/shop/product-basic/${product.slug}`}
                                className="lezada-button lezada-button--medium"
                              >
                                Select option
                              </Link>
                            ) : product.stock && product.stock > 0 ? (
                              <button
                                onClick={() => addToCart(product, addToast)}
                                className={` lezada-button lezada-button--medium ${
                                  cartItem !== undefined &&
                                  cartItem.quantity > 0
                                    ? "active"
                                    : ""
                                } `}
                                disabled={
                                  cartItem !== undefined &&
                                  cartItem.quantity > 0
                                }
                                title={
                                  product !== undefined
                                    ? "Ajouté au panier"
                                    : "Ajouter au panier"
                                }
                              >
                                {cartItem !== undefined && cartItem.quantity > 0
                                  ? "Ajouté"
                                  : "Ajouter au panier"}
                              </button>
                            ) : (
                              <button
                                disabled
                                className="active lezada-button lezada-button--medium"
                              >
                                épuisé
                              </button>
                            )}
                          </td>

                          <td className="product-remove">
                            <button
                              onClick={() =>
                                deleteFromWishlist(product, addToast)
                              }
                            >
                              <IoIosClose />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Col>
              <Col lg={12} className="space-mb--r100">
                <div className="cart-coupon-area space-pt--30 space-pb--30">
                  <Row className="align-items-center">
                    <Col lg={5} className="text-left text-lg-right ml-auto">
                      <button
                        className="lezada-button lezada-button--medium"
                        onClick={() => deleteAllFromWishlist(addToast)}
                      >
                        vider
                      </button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <div className="item-empty-area text-center">
                  <div className="item-empty-area__icon space-mb--30">
                    <IoIosHeartEmpty />
                  </div>
                  <div className="item-empty-area__text">
                    <p className="space-mb--30">Aucun produit</p>
                    <Link
                      href="/shop/left-sidebar"
                      to={process.env.PUBLIC_URL + "/shop/left-sidebar"}
                      className="lezada-button lezada-button--medium"
                    >
                      Acheter
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </LayoutFive>
  );
};

const mapStateToProps = (state) => {
  return {
    wishlistItems: state.wishlistData,
    cartItems: state.cartData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item, addToast, quantityCount) => {
      dispatch(addToCart(item, addToast, quantityCount));
    },
    addToWishlist: (item, addToast) => {
      dispatch(addToWishlist(item, addToast));
    },
    deleteFromWishlist: (item, addToast) => {
      dispatch(deleteFromWishlist(item, addToast));
    },
    deleteAllFromWishlist: (addToast) => {
      dispatch(deleteAllFromWishlist(addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);
