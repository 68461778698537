import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  addToCart,
  decreaseQuantity,
  deleteFromCart,
  deleteAllFromCart,
  cartItemStock,
} from "../../redux/actions/cartActions";
import { getDiscountPrice } from "../../lib/product";
import { LayoutFive } from "../../components/Layout";
import { BreadcrumbOne } from "../../components/Breadcrumb";
import { IoIosClose, IoMdCart } from "react-icons/io";
import { useHistory } from "react-router-dom";
import Banner from "../../components/Banner/Banner";

const Cart = ({
  cartItems,
  decreaseQuantity,
  addToCart,
  deleteFromCart,
  deleteAllFromCart,
}) => {
  const [quantityCount] = useState(1);
  const { addToast } = useToasts();

  // > Calculate price
  let cartPriceHT = 0;
  let cartPriceTVA = 0;
  let cartPriceTTC = 0;
  let totalFrais = 0;

  const history = useHistory();

  useEffect(() => {
    document.querySelector("body").classList.remove("overflow-hidden");
  });

  const checkoutHandler = () => {
    history.push("/login-register?redirect=checkout");
    // history.push("/login-register");
    // history.push("/checkout");
  };

  return (
    <LayoutFive>
      {/* breadcrumb */}
      <Banner
        title="Mon panier"
        image="/images/banners/banner.jpg"
        link="panier"
      />

      {/* cart content */}
      <div className="cart-content space-mt--r130 space-mb--r130">
        <Container>
          {cartItems && cartItems.length >= 1 ? (
            <Row>
              <Col lg={12}>
                {/* cart table */}
                <table className="cart-table">
                  <thead>
                    <tr>
                      <th className="product-name" colSpan="2">
                        Produit(s)
                      </th>
                      <th className="product-price">Prix</th>
                      <th className="product-quantity">Quantitée(s)</th>
                      <th className="product-subtotal">Total</th>
                      <th className="product-remove">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.map((product, i) => {
                      const discountedPrice = getDiscountPrice(
                        product.price,
                        product.discount
                      ).toFixed(2);

                      cartPriceHT += discountedPrice * product.quantity;
                      // totalFrais += product.frais;
                      // cartPriceTVA += product.price * product.quantity * 0.2;
                      // cartPriceTTC = cartPriceTVA + cartPriceHT;
                      cartPriceTTC = cartPriceHT + 4.5;
                      return (
                        <tr key={i}>
                          <td className="product-thumbnail">
                            <Link
                              // href={`/shop/product-basic/[slug]?slug=${product.slug}`}
                              to={`/shop/product-detail/${product.slug}?id=${product.id}`}
                            >
                              <img
                                src={product.image[0]}
                                className="img-fluid"
                                alt=""
                              />
                            </Link>
                          </td>
                          <td className="product-name">
                            <Link
                              href={`/shop/product-basic/[slug]?slug=${product.slug}`}
                              to={`${process.env.PUBLIC_URL}/shop/product-basic/${product.slug}`}
                            >
                              {product.name}
                            </Link>
                            {product.selectedProductColor &&
                            product.selectedProductSize ? (
                              <div className="product-variation">
                                <span>
                                  Couleur: {product.selectedProductColor}
                                </span>
                                <span>
                                  Taille: {product.selectedProductSize}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </td>

                          <td className="product-price">
                            <span className="price">{discountedPrice} €</span>
                          </td>

                          <td className="product-quantity">
                            <div className="cart-plus-minus">
                              <button
                                className="dec qtybutton"
                                onClick={() =>
                                  decreaseQuantity(product, addToast)
                                }
                              >
                                -
                              </button>
                              <input
                                className="cart-plus-minus-box"
                                type="text"
                                value={product.quantity}
                                readOnly
                              />

                              <button
                                className="inc qtybutton"
                                onClick={() => {
                                  addToCart(product, addToast, quantityCount);
                                }}
                                disabled={
                                  product !== undefined &&
                                  product.quantity &&
                                  product.quantity >=
                                    cartItemStock(
                                      product,
                                      product.selectedProductColor,
                                      product.selectedProductSize
                                    )
                                }
                              >
                                +
                              </button>
                            </div>
                          </td>

                          <td className="total-price">
                            <span className="price">
                              {(discountedPrice * product.quantity).toFixed(2)}{" "}
                              €
                            </span>
                          </td>

                          <td className="product-remove">
                            <button
                              onClick={() => deleteFromCart(product, addToast)}
                            >
                              <IoIosClose />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Col>
              <Col lg={12} className="space-mb--r100">
                <div className="cart-coupon-area space-pt--30 space-pb--30">
                  <Row className="align-items-center">
                    <Col lg={7} className="space-mb-mobile-only--30">
                      {/* <div className="lezada-form coupon-form">
                        <form>
                          <Row>
                            <Col md={7}>
                              <input
                                type="text"
                                placeholder="Enter your coupon code"
                              />
                            </Col>
                            <Col md={5}>
                              <button className="lezada-button lezada-button--medium">
                                apply coupon
                              </button>
                            </Col>
                          </Row>
                        </form>
                      </div> */}
                    </Col>
                    <Col lg={5} className="text-left text-lg-right">
                      <button
                        className="lezada-button lezada-button--medium"
                        onClick={() => deleteAllFromCart(addToast)}
                      >
                        vider le panier
                      </button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={5} className="ml-auto">
                <div className="cart-calculation-area">
                  <h2 className="space-mb--40">Cart totals</h2>
                  <table className="cart-calculation-table space-mb--40">
                    <tbody>
                      {/* <tr>
                        <th>PRIX HT : </th>
                        <td className="subtotal">{cartPriceHT.toFixed(2)} €</td>
                      </tr>
                      <tr>
                        <th>TVA :</th>
                        <td className="subtotal">
                          {cartPriceTVA.toFixed(2)} €
                        </td>
                      </tr> */}
                      <tr>
                        <th>Frais de livraison:</th>
                        <td className="subtotal">4,50 €</td>
                      </tr>
                      <tr>
                        <th>PRIX TTC:</th>
                        <td className="total">{cartPriceTTC.toFixed(2)} €</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="cart-calculation-button text-center">
                    <button
                      disabled={cartItems.length === 0}
                      onClick={checkoutHandler}
                      className="lezada-button lezada-button--medium"
                    >
                      payer
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <div className="item-empty-area text-center">
                  <div className="item-empty-area__icon space-mb--30">
                    <IoMdCart />
                  </div>
                  <div className="item-empty-area__text">
                    <p className="space-mb--30">Aucun produit</p>
                    <Link
                      // href="/shop/left-sidebar"
                      to="/shop"
                      className="lezada-button lezada-button--medium"
                    >
                      Boutique
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </LayoutFive>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item, addToast, quantityCount) => {
      dispatch(addToCart(item, addToast, quantityCount));
    },
    decreaseQuantity: (item, addToast) => {
      dispatch(decreaseQuantity(item, addToast));
    },
    deleteFromCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    },
    deleteAllFromCart: (addToast) => {
      dispatch(deleteAllFromCart(addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
