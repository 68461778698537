import React from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
import Loader from "../Loader/Loader";
import { Row, Col, ListGroup } from "react-bootstrap";

const CLIENT_ID =
  process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_PAYPAL_LIVE_KEY
    : process.env.REACT_APP_PAYPAL_SANDBOX_KEY;

let PayPalButton = null;

export default function ReactPayPal({
  total,
  currency,
  onSuccess,
  onError,
  onCancel,
}) {
  const [paid, setPaid] = React.useState(false);
  const [error, setError] = React.useState(null);
  const paypalRef = React.useRef();

  // To show PayPal buttons once the component loads
  React.useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "Paiement lovelyhouse",
                amount: {
                  currency_code: currency,
                  value: total,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          setPaid(true);
          // console.log("ORDER PAYER", order);
          onSuccess(order);
        },
        onError: (err) => {
          setError(err);
          onError(err);
          console.error(err);
        },
      })
      .render(paypalRef.current);
  }, []);

  // If the payment has been made
  if (paid) {
    return <div>Payment successful.!</div>;
  }

  // If any error occurs
  if (error) {
    return <div>Error Occurred in processing payment.! Please try again.</div>;
  }

  // Default Render
  return (
    <div>
      <div ref={paypalRef} />
    </div>
  );
}
