import React, { useEffect, useState } from "react"
import { LayoutFive } from "../../components/Layout"
import { Tab, Nav, Container, Col, Row } from "react-bootstrap"
import Banner from "../../components/Banner/Banner"
import { useTranslation } from "react-i18next"
import { ContainHome } from "../home/home.style"
import { Image } from "antd"
import Axios from "axios"

const Service = () => {
  const { t } = useTranslation()
  const [prestations, setPrestations] = useState()
  const [active, setActive] = useState("")

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_REST_API_URL}api/other/prestation`).then(
      (response) => {
        setPrestations(response.data)
        if (response.data.length !== 0) {
          setActive(response.data[0].slug)
        }
      }
    )
  }, [])

  return (
    <LayoutFive>
      <ContainHome>
        <div className="servise">
          <Banner
            title="Offres et Prestations"
            image="/images/banners/banner.jpg"
            // link="service"
          />

          <div
            className="content"
            style={{
              color: "black",
              fontFamily: "times new roman",
              marginBottom: "1.6rem"
            }}
          >
            <section className="service-content">
              {prestations ? (
                <Container>
                  <Tab.Container
                    id="controlled-tab-example"
                    defaultActiveKey={active}
                    activeKey={active}
                    onSelect={(k) => setActive(k)}
                  >
                    <Row>
                      <Col
                        md={12}
                        className="service-tab"
                        style={{
                          fontFamily: "theano",
                          fontSize: "20px"
                        }}
                      >
                        <Nav className="flex-row tab-service">
                          {prestations &&
                            prestations.map((prestation) => (
                              <Nav.Item key={prestation.id}>
                                <Nav.Link
                                  eventKey={prestation.slug}
                                  style={{
                                    fontFamily: "theano",
                                    fontSize: "18px"
                                  }}
                                >
                                  {prestation.name}
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                          {/* <Nav.Item>
                          <Nav.Link
                            eventKey="mas-amas"
                            style={{
                              fontFamily: "theano",
                              fontSize: "18px"
                            }}
                          >
                            {t("service.servTab4")}
                          </Nav.Link>
                        </Nav.Item> */}
                        </Nav>
                      </Col>
                      <Col md={12}>
                        <Tab.Content>
                          {prestations &&
                            prestations.map((prestationE) => (
                              <Tab.Pane
                                eventKey={prestationE.slug}
                                key={prestationE.id}
                              >
                                <div className="flex-container">
                                  <div className="flex-item-left">
                                    <Image.PreviewGroup>
                                      <Image
                                        src={prestationE.text[0].url}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </Image.PreviewGroup>
                                  </div>
                                  <div className="flex-item-right">
                                    <Image.PreviewGroup>
                                      <Image
                                        src={prestationE.image[0].url}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </Image.PreviewGroup>
                                  </div>
                                </div>
                              </Tab.Pane>
                            ))}
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </Container>
              ) : (
                <div>Aucune prestation</div>
              )}
            </section>
          </div>
        </div>
      </ContainHome>
    </LayoutFive>
  )
}

export default Service
