import React, { Fragment } from "react";
// import Link from "next/link";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import HeaderFive from "../../components/Header/HeaderFive.js";

const NotFound = () => {
  return (
    <Fragment>
      {/* <HeaderFive /> */}
      <div
        className="nothing-found-area bg-404"
        style={{
          backgroundImage: `url(${"/images/backgrounds/404.jpg"})`,
          backgroundSize: "contain",
        }}
      >
        <Container>
          <Row>
            <Col lg={6}>
              <div className="nothing-found-content">
                <h1>Oops!</h1>
                <h1 className="space-mb--50">Page not found!</h1>
                <p
                  className="direction-page"
                  style={{ background: "white", Padding: "10px" }}
                >
                  PLEASE GO BACK TO{" "}
                  <Link to="/" as={"/"}>
                    Homepage
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default NotFound;
