import React from "react";
import { FaFacebookF, FaInstagram, FaPinterest } from "react-icons/fa";
import {
  IoIosPhonePortrait,
  IoMdMail,
  IoLogoTwitter,
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoPinterest,
  IoMdPerson,
  IoIosArrowDown,
} from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/actions/userActions";

import { Link } from "react-router-dom";

const MobileMenuWidgets = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="offcanvas-mobile-menu__widgets">
      <div className="contact-widget space-mb--30">
        <ul>
          {/* <li>
            <Link to="/">
              <IoMdPerson />
            </Link>
            {userInfo ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span className="ml-2 mr-2">
                  {userInfo && userInfo.data
                    ? userInfo.data.name
                    : userInfo.name}
                </span>
                <IoIosArrowDown />
                <ul className="sub-menu sub-menu--mega sub-menu--mega--column-2">
                  <ul className="sub-menu--mega__list">
                    <li>
                      <Link
                        // href="/other/my-account"
                        to={"/account"}
                      >
                        Mon compte
                      </Link>
                    </li>
                    <li onClick={handleLogout}>
                      <Link to="/">Déconnexion</Link>
                    </li>
                  </ul>
                </ul>
              </div>
            ) : (
              <Link
                // href="/other/login-register"
                to="/login-register"
              >
                Connexion
              </Link>
            )}
            
          </li> */}
          <li>
            <IoIosPhonePortrait />
            <a href="tel://+33 01 85 11 28 39">+33 01 85 11 28 39</a>
          </li>
          <li>
            <IoMdMail />
            <a href="mailto:info@lovelyhouse.fr">info@lovelyhouse.fr</a>
          </li>
        </ul>
      </div>

      <div className="social-widget">
        <a
          href="https://www.facebook.com/LovelyHouse-Paris-106769757673769"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebookF />
        </a>
        <a
          href="https://www.instagram.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram />
        </a>
        <a
          href="https://www.pinterest.fr/rissjay/_saved/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaPinterest />
        </a>
      </div>
    </div>
  );
};

export default MobileMenuWidgets;
