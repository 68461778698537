import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { LayoutFive } from "../../components/Layout";
import { BreadcrumbOne } from "../../components/Breadcrumb";
import SignIn from "../../components/sign-in/sign-in.component";
import SignUp from "../../components/sign-up/sign-up.component";
import Banner from "../../components/Banner/Banner";

const LoginRegister = () => {
  return (
    <LayoutFive>
      {/* breadcrumb */}
      <Banner
        title="Connectez-vous"
        image="/images/banners/banner.jpg"
        link="connexion"
      />
      <div className="login-area space-mt--r130 space-mb--r130">
        <Container>
          <Row>
            <SignIn />
            <SignUp />
          </Row>
        </Container>
      </div>
    </LayoutFive>
  );
};

export default LoginRegister;
