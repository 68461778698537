import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers/rootReducer";
import promiseMiddleware from "redux-promise";

const persistConfig = {
  key: "primary",
  storage,
};

const cartItemsFromStorage = localStorage.getItem('cartItems')
    ? JSON.parse(localStorage.getItem('cartItems'))
    : []

const userInfoFromStorage = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : []

const shippingAddressFromStorage = localStorage.getItem('shippingAddress ')
    ? JSON.parse(localStorage.getItem('shippingAddress '))
    : {}


const initialState = {
    cartData: cartItemsFromStorage ,
    shippingAddress: {shippingAddressFromStorage},
    userLogin: { userInfo: userInfoFromStorage }
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export function initializeStore() {
  return createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(applyMiddleware(promiseMiddleware, thunkMiddleware))
  );
}

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(promiseMiddleware, thunkMiddleware))
);

export const persistor = persistStore(store);
