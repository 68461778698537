import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import {
  userLoginReducer,
  userRegisterReducer,
  shippingReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
} from "./userReducer.js";
import { combineReducers } from "redux";
import {
  orderCreateReducer,
  orderUpdateReducer,
  orderDetailsReducer,
  orderListMyReducer,
  orderPayReducer,
} from "./orderReducer";

const rootReducer = combineReducers({
  productData: productReducer,
  cartData: cartReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdate: userUpdateProfileReducer,
  shippingAddress: shippingReducer,
  orderCreate: orderCreateReducer,
  orderPay: orderPayReducer,
  orderListMy: orderListMyReducer,
  orderDetails: orderDetailsReducer,
  orderUpdate: orderUpdateReducer,
});

export default rootReducer;
